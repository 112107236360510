import { EDITOR_TYPES, IGroupInputControls } from '@uplink-shared/form-controls';
import { CityModel, CountryModel, StateModel, useBaseUpsertComponent } from '@uplink/shared';
import React, { FC, useEffect, useState } from 'react';
import { SETTING_ID, SettingBaseModel, VendorAddressModel, VendorManagmentModel } from '../../../../../../Shared';
import { IAPIGridRequest, IAPISearchFiltersDictionary, IClasses, IOptionValue, Utilities } from '@wings-shared/core';
import { observer, inject } from 'mobx-react';
import { PassengerLogisticsStore, SettingsStore, VendorManagementStore } from '../../../../../../../Stores';
import ViewInputControls from '../../../../../../Shared/Components/ViewInputControls/ViewInputControls';
import { withStyles } from '@material-ui/core';
import { styles } from './Landside.styles';
import { DropOffAddressModel } from '../../../../../../Shared/Models/DropOffAddress.model';

type Props = {
  key: string;
  useUpsert: any;
  searchFilters?: IAPISearchFiltersDictionary;
  vendorManagementStore: VendorManagementStore;
  passengerLogisticsStore: PassengerLogisticsStore;
  data?: DropOffAddressModel;
  settingsStore: SettingsStore;
  classes?: IClasses;
};

const Landside: FC<Props> = ({
  classes,
  searchFilters,
  vendorManagementStore,
  passengerLogisticsStore,
  settingsStore,
  data,
  useUpsert,
  key,
}) => {
  const [ countryList, setCountryList ] = useState<CountryModel[]>([]);
  const [ statesList, setStatesList ] = useState<StateModel[]>([]);

  const loadSettings = () => {
    settingsStore.getSettings(SETTING_ID.SETTING_DRIVER_LOCATION_CREW).subscribe();
  };

  useEffect(() => {
    if (data) {
      setFormValues(DropOffAddressModel.deserialize(data));
    }
  }, [ data ]);

  useEffect(() => {
    setRequiredRules();
  }, [ useUpsert.form.changed ]);

  const setRequiredRules = () => {
    useUpsert.getField('pickUpLocationCrew').set('rules', 'required');
    useUpsert.getField('pickUpLocationPax').set('rules', 'required');
    useUpsert.getField('addressLine1').set('rules', 'required|string|between:1,200');
    useUpsert.getField('hqAddressCountry').set('rules', 'required');
    useUpsert.getField('hqAddressCity').set('rules', 'required');
    useUpsert.getField('zipCode').set('rules', 'required|string|between:1,10');
  };

  useEffect(() => {
    vendorManagementStore.getVmsCountryCode().subscribe(response => {
      setCountryList(response.results);
    });
    loadSettings();
    useUpsert.getField('addressLine1').$placeholder = 'Enter Address';
    useUpsert.getField('zipCode').$placeholder = 'Enter Zip code';
  }, []);

  const setFormValues = data => {
    useUpsert.setFormValues(data);
    const vendorAddress: VendorAddressModel = VendorAddressModel.deserialize(data);
    if (vendorAddress) {
      const countryModel = new CountryModel({
        id: vendorAddress?.countryReference.countryId,
        commonName: vendorAddress?.countryReference?.name,
        isO2Code: vendorAddress?.countryReference?.code,
      });
      const stateModel = new StateModel({
        id: vendorAddress?.stateReference?.stateId,
        commonName: vendorAddress?.stateReference?.name,
        code: vendorAddress?.stateReference?.code,
      });
      const cityModel = new CityModel({
        id: vendorAddress?.cityReference?.id,
        cappsCode: vendorAddress?.cityReference?.cappsCode,
        commonName: vendorAddress?.cityReference?.commonName,
      });
      useUpsert.getField('hqAddressCountry').set(countryModel);
      useUpsert.getField('hqAddressState').set(stateModel);
      useUpsert.getField('hqAddressCity').set(cityModel);
    }
  };

  const groupInputControls = (): IGroupInputControls[] => {
    return [
      {
        title: '',
        inputControls: [
          {
            fieldKey: 'pickUpLocationCrew',
            type: EDITOR_TYPES.DROPDOWN,
            options: SettingBaseModel.deserializeList(settingsStore.pickupLocationCrew),
            isFullFlex: true,
            getOptionLabel: (option: IOptionValue) => (option as SettingBaseModel)?.name,
          },
          {
            fieldKey: 'pickUpLocationPax',
            type: EDITOR_TYPES.DROPDOWN,
            options: SettingBaseModel.deserializeList(settingsStore.pickupLocationCrew),
            isFullFlex: true,
            getOptionLabel: (option: IOptionValue) => (option as SettingBaseModel)?.name,
          },
          {
            fieldKey: 'addressLine1',
            type: EDITOR_TYPES.TEXT_FIELD,
            isFullFlex: true,
          },
          {
            fieldKey: 'hqAddressCountry',
            type: EDITOR_TYPES.DROPDOWN,
            options: countryList,
            showLabel: false,
            isHalfFlex: true,
          },
          {
            fieldKey: 'hqAddressState',
            type: EDITOR_TYPES.DROPDOWN,
            options: statesList,
            showLabel: false,
            isDisabled: !isCountrySelected(),
            getOptionLabel: state => (state as StateModel)?.label,
            isHalfFlex: true,
          },
          {
            fieldKey: 'hqAddressCity',
            type: EDITOR_TYPES.DROPDOWN,
            options: vendorManagementStore.cities,
            isDisabled: !isCountrySelected(),
            showLabel: false,
            isHalfFlex: true,
          },
          {
            fieldKey: 'zipCode',
            type: EDITOR_TYPES.TEXT_FIELD,
            isHalfFlex: true,
            showLabel: false,
          },
        ],
      },
    ];
  };

  const onValueChange = (value: IOptionValue, fieldKey: string): void => {
    const requiredFields = [
      'pickUpLocationCrew',
      'pickUpLocationPax',
      'addressLine1',
      'zipCode',
      'hqAddressCountry',
      'hqAddressCity',
    ];

    useUpsert.getField(fieldKey).set(value);
    switch (fieldKey) {
      case 'hqAddressCountry':
        vendorManagementStore.cities = [];
        vendorManagementStore.states = [];
        useUpsert.getField('hqAddressState').clear();
        useUpsert.getField('hqAddressCity').clear();
        filterStateByCountry(value);
        setCountryList(vendorManagementStore.countries);
        loadCities('');
        break;
      case 'hqAddressState':
        vendorManagementStore.cities = [];
        useUpsert.getField('hqAddressCity').clear();
        break;
      case 'hqAddressCity':
        vendorManagementStore.cities = [];
        break;
      default:
        break;
    }
    const formValues = useUpsert.form.values();
    const allFieldsFilled = requiredFields.every(field => {
      const fieldValue = formValues[field];
      return fieldValue && (typeof fieldValue !== 'object' || fieldValue.id);
    });
    passengerLogisticsStore.disableSaveButton = !allFieldsFilled;
  };

  const filterStateByCountry = (value?: any) => {
    const filter = value
      ? JSON.stringify([
        {
          propertyName: 'Country.CountryId',
          propertyValue: value.id,
        },
      ])
      : '';

    const request: IAPIGridRequest = {
      filterCollection: filter,
    };
    vendorManagementStore.getVmsStates(request, undefined).subscribe(response => {
      setStatesList(response.results);
    });
  };

  const loadCities = (searchValue: string): void => {
    const countryId: number = useUpsert.getField('hqAddressCountry').value?.id;
    if (!countryId || !searchValue) {
      vendorManagementStore.cities = [];
      return;
    }
    const stateId: number = useUpsert.getField('hqAddressState').value?.id;
    const filters = stateId
      ? Utilities.getFilter('State.StateId', stateId)
      : Utilities.getFilter('Country.CountryId', countryId);

    const searchCityFilter = searchValue
      ? [
        {
          propertyName: 'CommonName',
          propertyValue: searchValue,
        },
        {
          propertyName: 'OfficialName',
          operator: 'or',
          propertyValue: searchValue,
        },
      ]
      : [];

    const filterCollection = [ filters ];
    const request: IAPIGridRequest = {
      filterCollection: JSON.stringify(filterCollection),
      searchCollection: JSON.stringify(searchCityFilter),
    };
    vendorManagementStore.getVmsCities(request).subscribe();
  };

  const isCountrySelected = (): boolean => {
    const { value } = useUpsert.getField('hqAddressCountry');
    return Boolean((value as CountryModel)?.id);
  };

  const onSearch = (searchValue: string, fieldKey: string): void => {
    switch (fieldKey) {
      case 'hqAddressCountry':
        const filteredList = vendorManagementStore.countries.filter(country => {
          return (
            country.commonName?.toLowerCase().includes(searchValue.toLowerCase()) ||
            country.isO2Code?.toLowerCase().includes(searchValue.toLowerCase())
          );
        });
        setCountryList(filteredList);
        break;
      case 'hqAddressCity':
        loadCities(searchValue);
        break;
      case 'hqAddressState':
        const filteredStates = vendorManagementStore.states.filter(data => {
          return (
            data.commonName?.toLowerCase().includes(searchValue.toLowerCase()) ||
            data.cappsName?.toLowerCase().includes(searchValue.toLowerCase())
          );
        });
        setStatesList(filteredStates);
        break;
      default:
        break;
    }
    return;
  };

  const onFocus = (fieldKey: string): void => {
    switch (fieldKey) {
      case 'hqAddressState':
        const { value } = useUpsert.getField('hqAddressCountry');
        filterStateByCountry(value);
        break;
      case 'hqAddressCountry':
        setCountryList(vendorManagementStore.countries);
        break;
      case 'hqAddressCity':
        if (!useUpsert.getField('hqAddressCity').value) {
          vendorManagementStore.cities = [];
          useUpsert.getField('hqAddressCity').clear();
        }
        break;
      default:
        break;
    }
  };

  return (
    <div className={classes.formAddress}>
      <ViewInputControls
        isEditable={true}
        groupInputControls={groupInputControls()}
        onGetField={(fieldKey: string) => useUpsert.getField(fieldKey)}
        onValueChange={(option, fieldKey) => onValueChange(option, fieldKey)}
        field={fieldKey => useUpsert.getField(fieldKey)}
        onSearch={(searchValue: string, fieldKey: string) => onSearch(searchValue, fieldKey)}
        onFocus={fieldKey => onFocus(fieldKey)}
      />
    </div>
  );
};

export default inject(
  'vendorManagementStore',
  'settingsStore',
  'passengerLogisticsStore'
)(withStyles(styles)(observer(Landside)));
