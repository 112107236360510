import React, { useEffect } from 'react';
import { Box, List, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import { BaseStore, DataExpiryStore, VendorDocumentStore } from '../../../../Stores';
import { IClasses, UIStore } from '@wings-shared/core';
import { RootDataStore, SidebarStore } from '@uplink-shared/layout';
import { useLocation, useNavigate } from 'react-router';
import { useStyles } from './ExpiryDocument.styles';
import { PrimaryButton } from '@uvgo-shared/buttons';
import LocationDocuments from '../../../VendorLocations/Components/LocationDocument/LocationDocuments';
import { DataExpirationUptoDate } from '../../../Shared/Models/DataExpirationUptoDate.model';
import { finalize, takeUntil } from 'rxjs/operators';
import { useUnsubscribe } from '@wings-shared/hooks';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import { Dialog } from '@uvgo-shared/dialog';
import { HelpRounded, FiberManualRecord } from '@material-ui/icons';

interface Props {
  classes?: IClasses;
  dataExpiryStore: DataExpiryStore;
  vendorDocumentStore: VendorDocumentStore;
}

const ExpiryDocument: React.FC<Props> = ({ dataExpiryStore, vendorDocumentStore }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dataExpiryState = location.state.state;
  const classes = useStyles();
  const unsubscribe = useUnsubscribe();

  useEffect(() => {
    if (location.state.state) {
      dataExpiryStore.dataExpiryState = location.state.state;
    }
    SidebarStore.setNavLinks([], 'vendor', 'Documents', '/vendor');
  }, [ location.state.state, dataExpiryStore?.dataExpiryState ]);

  const dialogContent = () => {
    return (
      <List>
        {dataExpiryStore.documentNameList &&
          dataExpiryStore.documentNameList.map(item => (
            <ListItem>
              <ListItemIcon style={{ minWidth: 0, marginRight: '10px' }}>
                <FiberManualRecord fontSize="small" />
              </ListItemIcon>
              <ListItemText primary={item} />
            </ListItem>
          ))}
      </List>
    );
  };

  const daysLeftUntilExpiry = (lastExpiryDate) => {
    const currentDate = new Date();
    const expiryDate = new Date(lastExpiryDate);
    const differenceInMilliseconds = expiryDate - currentDate;
    const differenceInDays = Math.ceil(differenceInMilliseconds / (1000 * 60 * 60 * 24));
    return differenceInDays <= 15;
  };

  useEffect(() => {
    if (vendorDocumentStore.vendorDocuments && vendorDocumentStore.vendorDocuments.length > 0) {
      const documentNameList = vendorDocumentStore.vendorDocuments
        .filter(document => document.endDate && daysLeftUntilExpiry(document.endDate))
        .map(document => document.documentName.name);
  
      if (JSON.stringify(dataExpiryStore.documentNameList) !== JSON.stringify(documentNameList)) {
        dataExpiryStore.documentNameList = documentNameList;
      }
    }
  }, [ vendorDocumentStore.vendorDocuments, dataExpiryStore ]);

  
  const popupModal = () => {
    ModalStore.open(
      <Dialog
        title="Please upload following document(s) to confirm all are up to date:"
        open={true}
        onClose={() => ModalStore.close()}
        dialogContent={dialogContent}
        dialogActions={() => null}
        isPreventDrag={true}
      />
    );
  };

  const handleConfirm = () => {
    UIStore.setPageLoader(true);
    const request = new DataExpirationUptoDate({
      vendorLocationId: RootDataStore.locationData.locationId,
      dataExpSectionTypeId: dataExpiryStore.dataExpiryState?.sectionType.id,
    });
    dataExpiryStore
      ?.upsertUpToDateConfirm(request.serialize())
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe({
        next: (response: DataExpirationUptoDate) => {
          if (response.isExpiryDataUpdate) {
            BaseStore.showAlert('Data updated successfully', 0);
            navigate('/vendor', {
              state: {
                activeStep: location.state?.activeStep,
              },
            });
          } else if (dataExpiryStore.documentNameList.length > 0) {
            popupModal();
          } else {
            BaseStore.showAlert(response.message.toString(), 0);
          }
        },
        error: error => {
          BaseStore.showAlert(error.message, 0);
        },
      });
  };

  return (
    <Box>
      <Box className={classes.headerConfirmation}>
        <Box className={classes.innerHeader}>
          <HelpRounded style={{ color: '#D16124' }} />
          <Typography>
            Are your <strong>{dataExpiryState?.sectionTypeName}</strong>
            {' for '}
            {dataExpiryState?.vendorLocation?.name} -{' '}
            {dataExpiryState?.vendorLocation?.airportReference?.displayCode ||
              dataExpiryStore.dataExpiryState?.vendorLocation?.vendorLocationCityReferenceModel?.cityReference
                ?.cappsCode}{' '}
            updated?
          </Typography>
        </Box>
        <Box>
          <PrimaryButton
            variant="text"
            onClick={() =>
              navigate('/vendor', {
                state: {
                  activeStep: location.state?.activeStep,
                },
              })
            }
          >
            Back
          </PrimaryButton>
          <PrimaryButton variant="contained" onClick={handleConfirm}>
            Confirm
          </PrimaryButton>
        </Box>
      </Box>
      <LocationDocuments />
    </Box>
  );
};

export default inject('dataExpiryStore', 'vendorDocumentStore')(observer(ExpiryDocument));
