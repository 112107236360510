import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  headerConfirmation: {
    padding: '4px 14px 4px 10px',
    borderRadius: '4px',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '10px',
  },
  cardHeader:{
    width: '100%',
  },
  innerHeader:{
    display: 'flex',
    alignItems: 'center',
    gap: '10px'
  },
  defaultButton: {
    '& button': {
      border: '1px solid #1976D2',
      padding: '4px 10px',
      height: '40px',
      borderRadius: '4px',
      fontSize: '14px',
      fontWeight: '400',
      minWidth: '100px',
      textTransform: 'capitalize',
      color: '#1976D2',
    },
  },
  primaryButton: {
    '& button': {
      background: '#1976D2',
      color: '#fff',
      minWidth: '100px',
      height: '40px',
      marginLeft: '10px'
    },
  },
}));
