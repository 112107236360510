import React, { useState, useEffect, ReactNode } from 'react';
import { Box, Typography, Paper } from '@material-ui/core';
import { useStyles } from './DataExpiryStepper.styles';
import { DetailsEditorWrapper, RootDataStore, SidebarStore } from '@uplink-shared/layout';
import {
  ApprovalDataModel,
  DataExpirationModel,
  DataExpirationSectionTypeModel,
  ENTITY_NAMES,
  OperationalEssentialsModel,
  SliderSideBarMenu,
  VALIDATION_REGEX,
  VendorLocationModel,
} from '../../../Shared';
import { PrimaryButton } from '@uvgo-shared/buttons';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import { inject, observer } from 'mobx-react';
import { useLocation, useNavigate } from 'react-router';
import { UIStore } from '@wings-shared/core';
import { BaseStore, DataExpiryStore, VendorLocationStore } from '../../../../Stores';
import { finalize, takeUntil } from 'rxjs/operators';
import { useUnsubscribe } from '@wings-shared/hooks';
import ExpirySliderWrapper from '../../Components/ExpirySliderWrapper/ExpirySliderWrapper';
import { EDITOR_TYPES } from '@uplink-shared/form-controls';
import LaunchIcon from '@material-ui/icons/LaunchRounded';
import { ApprovalDataStore } from '../../../../Stores/ApprovalData.store';
import { DataExpirationUptoDate } from '../../../Shared/Models/DataExpirationUptoDate.model';
import ExpiryQuestionaire from '../../../Shared/Components/Questionaire/ExpiryQuestionaire';
interface Props {
  dataExpirationData?: DataExpirationModel[];
  vendorLocationStore: VendorLocationStore;
  approvalDataStore: ApprovalDataStore;
  dataExpiryStore: DataExpiryStore;
}

export interface DataExpirySliderProps {
  slide: number;
  title: string;
  heading: string;
  buttonText: string;
  component: any;
  redirectUrl: string;
  isComponent?: boolean;
  sectionType: DataExpirationSectionTypeModel;
  sectionTypeName: string;
  vendorLocation: VendorLocationModel;
}

enum VendorEntity {
  VENDOR_ADDRESS = 'VendorAddress',
  VENDOR_CONTACT = 'VendorContact',
  VENDOR_DOCUMENT = 'VendorDocument',
  VENDOR_LOCATION_ADDRESS = 'VendorLocationAddress',
  VENDOR_LOCATION_CONTACT = 'VendorLocationContact',
  VENDOR_LOCATION_DOCUMENT = 'VendorLocationDocument',
  VENDOR_LOCATION_SERVICE_COMM = 'VendorLocationServiceComm',
  SERVICE_ITEM_PRICING = 'ServiceItemPricing',
  AIR_TO_GROUND_FREQUENCY = 'AirToGroundFrequency',
  MANAGER_NAME = 'ManagerName',
  ASST_MANAGER_NAME = 'AsstManagerName',
  OPERATION_TYPE = 'OperationType',
}

const DataExpiryStepper: React.FC<Props> = ({
  dataExpiryStore,
  dataExpirationData,
  vendorLocationStore,
  approvalDataStore,
}) => {
  const location = useLocation();
  const [ activeStep, setActiveStep ] = useState<number>(location.state?.activeStep || 0);
  const [ dataExpiryState, setDataExpiryState ] = useState<DataExpirySliderProps[]>([]);
  const navigate = useNavigate();
  const classes = useStyles();
  const unsubscribe = useUnsubscribe();
  const expiryState = dataExpiryState[activeStep];
  const [ errors, setErrors ] = useState({});

  const profileEndAdornment = (): ReactNode => {
    return <LaunchIcon className={classes.buttonStyle} onClick={() => ModalStore.open(<ExpiryQuestionaire />)} />;
  };

  const errorHandler = (errors: object, id): void => {
    Object.values(errors)?.forEach(errorMessage => BaseStore.showAlert(errorMessage[0], id));
  };

  const initialExpiryData = (expiredData: DataExpirationModel[]) => {
    const uniqueDataMap = new Map();
    expiredData.forEach((item, index) => {
      let sectionTypeName: string = '',
        redirectUrl: string = '';
      let initialComponentData: any = {};
      let isComponent: boolean = false;
      switch (item.sectionType.name) {
        case VendorEntity.VENDOR_ADDRESS:
          sectionTypeName = 'Addresses';
          redirectUrl = 'location/expiry/addresses';
          break;
        case VendorEntity.VENDOR_CONTACT:
          sectionTypeName = 'Contacts';
          redirectUrl = 'location/expiry/contacts';
          break;
        case VendorEntity.VENDOR_DOCUMENT:
          sectionTypeName = 'Documents';
          redirectUrl = 'location/expiry/documents/';
          break;
        case VendorEntity.VENDOR_LOCATION_ADDRESS:
          sectionTypeName = 'Addresses';
          redirectUrl = 'location/expiry/addresses';
          break;
        case VendorEntity.VENDOR_LOCATION_CONTACT:
          sectionTypeName = 'Contacts';
          redirectUrl = 'location/expiry/contacts';
          break;
        case VendorEntity.VENDOR_LOCATION_DOCUMENT:
          sectionTypeName = 'Documents';
          redirectUrl = 'location/expiry/documents/';
          break;
        case VendorEntity.VENDOR_LOCATION_SERVICE_COMM:
          sectionTypeName = 'Service Comm';
          redirectUrl = 'location/operational-essential';
          break;
        case VendorEntity.SERVICE_ITEM_PRICING:
          sectionTypeName = 'Pricing';
          redirectUrl = 'location/expiry/pricing';
          break;
        case VendorEntity.AIR_TO_GROUND_FREQUENCY:
          sectionTypeName = 'Air To Ground Frequency';
          isComponent = true;
          initialComponentData = {
            fieldName: 'airToGroundFrequency',
            type: EDITOR_TYPES.TEXT_FIELD,
            label: 'Air To Ground Frequency',
            placeholder: 'Air To Ground Frequency',
            rules: 'numeric|between:0,999.99999|regex:/^\\d{1,3}(\\.\\d{1,5})?$/',
            isEditable: true,
          };
          break;
        case VendorEntity.MANAGER_NAME:
          sectionTypeName = 'Manager Name';
          isComponent = true;
          initialComponentData = {
            fieldName: 'managerName',
            type: EDITOR_TYPES.TEXT_FIELD,
            label: 'Manager Name',
            placeholder: 'Manager Name',
            rules: 'string|between:2,100',
            isEditable: true,
          };
          break;
        case VendorEntity.ASST_MANAGER_NAME:
          sectionTypeName = 'Assistant Manager Name';
          isComponent = true;
          initialComponentData = {
            fieldName: 'asstManagerName',
            type: EDITOR_TYPES.TEXT_FIELD,
            label: 'Assistant Manager Name',
            placeholder: 'Assistant Manager Name',
            rules: 'string|between:2,100',
            isEditable: true,
          };
          break;
        case VendorEntity.OPERATION_TYPE:
          sectionTypeName = 'Operation Type';
          isComponent = true;
          initialComponentData = {
            fieldName: 'appliedOperationType',
            type: EDITOR_TYPES.TEXT_FIELD,
            label: 'Operation Type',
            placeholder: 'Operation Type',
            endAdormentValue: profileEndAdornment(),
            rules: '',
            isEditable: false,
          };
          break;
      }
      if (sectionTypeName) {
        const uniqueKey = `${sectionTypeName}-${item.vendorLocation.vendorLocationId}`;
        if (!uniqueDataMap.has(uniqueKey)) {
          uniqueDataMap.set(uniqueKey, {
            slide: index,
            title: (
              <Box>
                Update your <b>{sectionTypeName}</b> for {item.vendorLocation.name} -
                {item.vendorLocation?.airportReference?.displayCode ||
                  item.vendorLocation?.vendorLocationCityReferenceModel?.cityReference?.cappsCode}
              </Box>
            ),
            heading: `
                Update your ${sectionTypeName} for ${item.vendorLocation.name} -
                ${item.vendorLocation?.airportReference?.displayCode ||
                  item.vendorLocation?.vendorLocationCityReferenceModel?.cityReference?.cappsCode}
              `,
            buttonText: `Go to ${sectionTypeName} Page`,
            isComponent: isComponent,
            redirectUrl: redirectUrl,
            component: {
              ...initialComponentData,
              ...(item.fieldValue !== undefined && item.fieldValue !== null && { fieldValue: item.fieldValue }),
            },
            sectionTypeName: sectionTypeName,
            sectionType: item.sectionType,
            vendorLocation: item.vendorLocation,
          });
        }
      }
    });
    setDataExpiryState(Array.from(uniqueDataMap.values()));
  };

  useEffect(() => {
    dataExpirationData && dataExpirationData?.length > 0 && initialExpiryData(dataExpirationData);
    SidebarStore.setNavLinks(SliderSideBarMenu(), 'vendor');
  }, [ dataExpirationData ]);

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const loadVendorLocationData = parseState => {
    UIStore.setPageLoader(true);

    vendorLocationStore
      ?.getVendorLocationById(parseState.vendorLocation.vendorLocationId)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe((response: VendorLocationModel) => {
        if (response) {
          RootDataStore.setLocation(
            true,
            response.id,
            response.name,
            response.code,
            response.airportReference?.id,
            '',
            response.airportDataManagement,
            response.countryDataManagement,
            response.permitDataManagement
          );
          navigate(parseState.redirectUrl, {
            state: {
              state: parseState,
              activeStep: activeStep,
            },
          });
        }
      });
  };

  const handleSubmit = (currentStep: DataExpirySliderProps): void => {
    UIStore.setPageLoader(true);
    dataExpiryStore.isExpiryDataUpdated = false;
    let newValue = dataExpiryStore.inputValue;
    if (currentStep.component.fieldName === 'appliedOperationType') {
      newValue = [
        {
          id: 0,
          operationTypeId: dataExpiryStore?.operationTypeId,
        },
      ];
      currentStep.component.fieldValue = [
        {
          id: currentStep.component.fieldValue.id,
          operationTypeId: currentStep.component.fieldValue.operationTypeId,
        },
      ];
    }
    approvalDataStore
      .upsertApprovalData([
        ApprovalDataModel.serializeExpiryData(
          currentStep.component.fieldName,
          ENTITY_NAMES.OperationalEssential,
          currentStep.component.fieldValue,
          newValue,
          currentStep?.vendorLocation.vendorLocationId
        ),
      ])
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe({
        next: (response: ApprovalDataModel[]) => {
          const request = new DataExpirationUptoDate({
            vendorLocationId: currentStep?.vendorLocation.vendorLocationId,
            dataExpSectionTypeId: currentStep?.sectionType.id,
          });
          dataExpiryStore
            ?.upsertUpToDateConfirm(request.serialize())
            .pipe(
              takeUntil(unsubscribe.destroy$),
              finalize(() => UIStore.setPageLoader(false))
            )
            .subscribe({
              next: (response: DataExpirationUptoDate) => {
                if (response.isExpiryDataUpdate) {
                  const excludedFields = [
                    'appliedOperationType',
                    'airToGroundFrequency',
                    'managerName',
                    'asstManagerName',
                  ];

                  if (!excludedFields.includes(currentStep.component.fieldName)) {
                    BaseStore.showAlert('Data updated successfully', 0);
                  }
                  dataExpiryStore.setInputValue('');
                  dataExpiryStore.isExpiryDataUpdated = true;
                }
              },
              error: error => {
                BaseStore.showAlert(error.message, 0);
              },
            });
        },
        error: error => {
          errorHandler(error.response.data.errors, '0');
        },
      });
  };

  const handleNext = () => {
    const currentStep = dataExpiryState[activeStep];
    if (currentStep?.isComponent) {
      handleSubmit(currentStep);
      return;
    }
    handleSkip();
  };

  const handleSkip = () => {
    const isLastStep = activeStep === dataExpiryState.length - 1;
    if (isLastStep) {
      navigate('/vendor/general-info');
      return;
    }
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  return expiryState ? (
    <DetailsEditorWrapper
      headerActions={<Typography variant="h5">{expiryState?.heading}</Typography>}
      isEditMode={true}
      classes={{ headerActions: classes.headerActions }}
    >
      <Box>
        <Box className={classes.innerBox2}>
          <Paper className={classes.paper}>
            <Box>
              <Box>{expiryState?.title}</Box>
              <Box className={classes.slideCount}>
                {expiryState?.slide + 1}/{dataExpiryState?.length}
              </Box>
            </Box>
            <Box>
              {!expiryState?.isComponent ? (
                <Box className={classes.primaryButton}>
                  <PrimaryButton
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      const sanitizedState = JSON.parse(JSON.stringify(expiryState));
                      loadVendorLocationData(sanitizedState);
                    }}
                  >
                    {expiryState?.buttonText}
                  </PrimaryButton>
                </Box>
              ) : (
                <ExpirySliderWrapper
                  sectionTypeName={expiryState?.sectionTypeName}
                  activeStep={activeStep}
                  errors={errors}
                  setErrors={setErrors}
                  {...expiryState.component}
                />
              )}
            </Box>
            <Box className={classes.innerBox3}>
              <Box className={classes.defaultButton}>
                {expiryState?.slide !== 0 && (
                  <PrimaryButton disabled={!activeStep || UIStore.pageLoading} variant="outlined" onClick={handleBack}>
                    Back
                  </PrimaryButton>
                )}
              </Box>
              <Box className={classes.buttonWrapper}>
                <Box className={classes.defaultButton}>
                  <PrimaryButton
                    variant="text"
                    color="primary"
                    disabled={UIStore.pageLoading}
                    onClick={handleSkip}
                  >
                    Skip
                  </PrimaryButton>
                </Box>
                {expiryState.isComponent && (
                  <Box className={classes.primaryButton}>
                    <PrimaryButton
                      variant="contained"
                      color="primary"
                      disabled={
                        UIStore.pageLoading || dataExpiryStore.inputValue === '' || errors[expiryState.component.type]
                      }
                      onClick={handleNext}
                    >
                      Save & Next
                    </PrimaryButton>
                  </Box>
                )}
              </Box>
            </Box>
          </Paper>
        </Box>
      </Box>
    </DetailsEditorWrapper>
  ) : (
    <></>
  );
};
export default inject('dataExpiryStore', 'vendorLocationStore', 'approvalDataStore')(observer(DataExpiryStepper));
