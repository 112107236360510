import { Box, InputAdornment, MenuItem, Select, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useStyles } from './ExpirySliderWrapper.styles';
import { EDITOR_TYPES, IGroupInputControls } from '@uplink-shared/form-controls';
import { IOptionValue, ISelectOption } from '@wings-shared/core';
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import { DataExpiryStore } from '../../../../Stores';
import { validateInput } from '../../Shared/DataExpiryCard/CardModal';

interface ExpirySliderWrapperProps {
  fieldName: string;
  type: EDITOR_TYPES;
  fieldValue: string;
  label?: string;
  rules?: string;
  placeholder?: string;
  isEditable?: boolean;
  options?: ISelectOption[];
  endAdormentValue?: string;
  dataExpiryStore: DataExpiryStore;
  errors: string, 
  setErrors: () => void;
}

const ExpirySliderWrapper: React.FC<ExpirySliderWrapperProps> = ({
  fieldName,
  type,
  label,
  rules,
  placeholder,
  fieldValue,
  options,
  isEditable,
  endAdormentValue,
  dataExpiryStore,
  errors,
  setErrors
}) => {
  const classes = useStyles();

  const handleInputChange = event => {
    const value = event.target.value;
    const error = validateInput(value, rules || '');
    setErrors({ ...errors, [type]: error });
    dataExpiryStore.setInputValue(value);
  };

  useEffect(() => {
    if (fieldName === 'appliedOperationType') {
      dataExpiryStore.setInputValue(fieldValue?.name);
    } else dataExpiryStore.setInputValue(fieldValue);
    setErrors({});
  }, [ fieldValue ]);

  const getEditableContent = () => {
    const customInputRoot = classNames({
      [classes.textInput]: true,
    });

    switch (type) {
      case EDITOR_TYPES.TEXT_FIELD:
        return (
          <TextField
            autoComplete="off"
            label={label || ''}
            type="text"
            variant="outlined"
            placeholder={placeholder || ''}
            InputLabelProps={{
              classes: {
                root: classes.labelRoot,
              },
            }}
            onChange={handleInputChange}
            value={dataExpiryStore.inputValue}
            InputProps={{
              endAdornment: <InputAdornment position="start">{endAdormentValue || ''}</InputAdornment>,
            }}
            error={!!errors[type]}
            helperText={errors[type]}
            onKeyDown={e => {
              if (!isEditable) {
                e.preventDefault();
              }
            }}
            classes={{ root: customInputRoot }}
          />
        );
      case EDITOR_TYPES.DROPDOWN:
        return (
          <Select variant="outlined" onChange={handleInputChange} displayEmpty classes={{ root: customInputRoot }}>
            {options?.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        );
      default:
        return null;
    }
  };

  return (
    <Box>
      <Box className={classes.inputContainer}>{getEditableContent()}</Box>
    </Box>
  );
};

export default inject('dataExpiryStore')(observer(ExpirySliderWrapper));
