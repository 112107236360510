import { CoreModel, modelProtection, ISelectOption } from '@wings-shared/core';
import { VendorLocationModel } from './VendorLocation.model';
import { SettingBaseModel } from './SettingBase.model';

@modelProtection
export class DataExpirationModel extends CoreModel implements ISelectOption {
  vendorLocation: VendorLocationModel = new VendorLocationModel();
  sectionType: SettingBaseModel = new SettingBaseModel();
  isExpired: boolean = false;
  firstExpiryDate: Date = new Date();
  lastExpiryDate: Date = new Date();
  fieldValue: string = '';

  constructor(data?: Partial<DataExpirationModel>) {
    super(data);
    Object.assign(this, data);
  }

  static deserialize(apiData: DataExpirationModel): DataExpirationModel {
    if (!apiData) {
      return new DataExpirationModel();
    }
    const data: Partial<DataExpirationModel> = {
      ...apiData,
      vendorLocation: VendorLocationModel.deserialize(apiData.vendorLocation),
      sectionType: SettingBaseModel.deserialize(apiData.sectionType),
      fieldValue: apiData.fieldValue ? DataExpirationModel.deserializeFieldValue(apiData.fieldValue) : null,
    };
    return new DataExpirationModel(data);
  }

  static deserializeFieldValue(fieldValue: string): string | SettingBaseModel | SettingBaseModel[] {
    const parsedFieldValue = JSON.parse(fieldValue);

    if (typeof parsedFieldValue === 'string') {
      return parsedFieldValue;
    }

    if (Array.isArray(parsedFieldValue)) {
      return parsedFieldValue.map(item => ({
        id: item.Id,
        operationTypeId: item.OperationTypeId,
        name: item.Name,
      }))[0];
    }

    if (typeof parsedFieldValue === 'object' && parsedFieldValue !== null) {
      return SettingBaseModel.deserialize(parsedFieldValue as SettingBaseModel);
    }

    return parsedFieldValue;
  }

  static deserializeList(apiDataList: DataExpirationModel[]): DataExpirationModel[] {
    return apiDataList ? apiDataList.map(apiData => DataExpirationModel.deserialize(apiData)) : [];
  }

  public get label(): string {
    return this.vendorLocation.name;
  }

  public get value(): string | number {
    return this.vendorLocation.name;
  }
}
