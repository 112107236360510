import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  innerBox2: {
    flexGrow: 1,
    paddingTop: 10,
    paddingBottom: 10,
    display: 'flex',
    marginTop: '10px',
    width: '100%',
    overflow: 'auto',
    scrollbarWidth: 'thin',
    scrollbarColor: '#D3D3D3 transparent',
  },
  paper: {
    minWidth: '407px',
    minHeight: '171px',
    padding: '26px 20px 20px 20px',
    gap: '2px',
    borderRadius: '4px',
    marginRight: '10px',
    marginLeft: '3px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  contentBox: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  textBox: {
    flex: 1,
    fontSize: '14px',
  },
  primaryButton: {
    marginLeft: 'auto',
    '& button': {
      background: '#1976D2',
      color: '#fff',
      minWidth: '100px',
      height: '40px',
      margin: 0,
    },
    '& button:disabled': {
      background: '#afafaf',
      border: 'none',
      color: 'white',
    },
  },
  defaultButton: {
    '& button': {
      border: '1px solid #1976D2',
      padding: '4px 10px',
      height: '40px',
      borderRadius: '4px',
      fontSize: '14px',
      fontWeight: '400',
      minWidth: '100px',
      textTransform: 'capitalize',
      color: '#1976D2',
    },
  },
  buttonStyle: {
    height: '25px',
    cursor: 'pointer',
    color: '#1976D2',
  },
}));
