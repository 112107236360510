import {
  IAPIGridRequest,
  IAPISearchFiltersDictionary,
  IClasses,
  IOptionValue,
  UIStore,
  Utilities,
  MODEL_STATUS
} from '@wings-shared/core';
import React, { FC, ReactNode, useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { forkJoin } from 'rxjs';
import { Box, Typography, withStyles } from '@material-ui/core';
import { MixPanelTrackingEvents, useBaseUpsertComponent, VIEW_MODE } from '@uplink/shared';
import { useGridState } from '@wings-shared/custom-ag-grid';
import { useUnsubscribe } from '@wings-shared/hooks';
import { EDITOR_TYPES, IGroupInputControls } from '@uplink-shared/form-controls';
import { DetailsEditorWrapper, ConfirmNavigate, SidebarStore, RootDataStore } from '@uplink-shared/layout';
import Button from '@material-ui/core/Button';
import { AnalyticsStore } from '@uplink-shared/analytics';
import { fields } from './Fields';
import {
  HandlerBulletinsModel,
  SETTING_ID,
  CustomTooltip,
  SidebarMenuOperationalInfo,
  ViewInputControls,
  Airports,
  SettingBaseModel,
  VendorLocationModel,
  CustomDialog,
  BULLETIN_LEVEL_SETTINGS,
  COLLECTION_NAMES,
} from '../../../../Shared';
import { styles } from './UpsertNotes.styles';
import { SettingsStore, BulletinStore, VendorLocationStore } from '../../../../../Stores';
import { finalize, takeUntil } from 'rxjs/operators';
import { useNavigate, useParams } from 'react-router';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import rootStore from '../../../../../Stores/VendorRoot.store';
import { deserialize } from 'v8';

interface Props {
  classes?: IClasses;
  bulletinStore: BulletinStore;
  vendorLocationStore: VendorLocationStore;
  searchFilters?: IAPISearchFiltersDictionary;
  settingsStore: SettingsStore;
}

const UpsertNotes: FC<Props> = ({ classes, bulletinStore, vendorLocationStore, settingsStore, searchFilters }) => {
  const gridState = useGridState();
  const unsubscribe = useUnsubscribe();
  const useUpsert = useBaseUpsertComponent<HandlerBulletinsModel>({}, fields, searchFilters);
  const formRef = useUpsert.form;
  const navigate = useNavigate();
  const params = useParams();
  const isEditable = useUpsert.isEditable;
  const [ noteTypes, setNoteTypes ] = useState([]);
  const [ airportLevels, setAirportLevels ] = useState([]);
  const [ noteLevels, setNoteLevels ] = useState([]);
  const isCountry = params.type === 'country';
  const isAirport = params.type === 'airport';
  const isHandler = params.type === 'handler';
  
  useEffect(() => {
    if (!RootDataStore.locationData.countryDataManagement && isCountry) {
      navigate('/vendor/locations');
    }
  }, [ RootDataStore.locationData.countryDataManagement ]);

  useEffect(() => {
    if (settingsStore.bulletinLevels) {
      setNoteLevels([
        ...settingsStore.bulletinLevels,
        ...SettingBaseModel.deserializeList([
          { id: 3, name: 'City' },
          { id: 4, name: 'Country' },
          { id: 5, name: 'State' },
        ]),
      ]);
    }
  }, [ settingsStore.bulletinLevels ]);

  useEffect(() => {
    useUpsert.setViewMode((params.upsert.toUpperCase() as VIEW_MODE) || VIEW_MODE.EDIT);
    SidebarStore.setNavLinks(SidebarMenuOperationalInfo(), 'vendor', 'Operational Information', '/vendor/locations');
    loadLocationAddress();
    settingsStore
      .getHandlerBulletinSettings(SETTING_ID.SETTING_BULLETIN_TYPES,
        BULLETIN_LEVEL_SETTINGS.AIRPORT)
      .subscribe(response => setNoteTypes(response.results));
    forkJoin([
      settingsStore.getHandlerBulletinSettings(SETTING_ID.SETTING_BULLETIN_LEVELS, 
        BULLETIN_LEVEL_SETTINGS.AIRPORT),
    ])
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe(response => {
        setAirportLevels(response[0].results);
        setAirportLevels(prevLevels => [
          ...prevLevels,
          ...SettingBaseModel.deserializeList([
            { id: 3, name: 'City' },
            { id: 4, name: 'Country' },
            { id: 5, name: 'State' },
          ]),
        ]);
      });
    useUpsert.getField('startDate').set(new Date());
    bulletinStore.isEndDateRequired = true;
    bulletinStore.isNotesTextExist = false;
    if (params?.id) {
      loadInitialData();
    }
    if (params.type !== 'handler') {
      loadAirportData();
    }
    AnalyticsStore.track(MixPanelTrackingEvents.VENDOR_GENERALINFORMATION);
    if (!useUpsert.getField('isUFN').value) {
      useUpsert.getField('endDate').set('label', 'End Date*');
    }
  }, []);

  const filterDefaultLevels = () => {
    switch (params.type) {
      case 'handler':
        return airportLevels.filter(item => item.id === 2);
      case 'airport':
        return airportLevels.filter(item => item.id === 1 || item.id === 3);
      case 'country':
        return airportLevels.filter(item => item.id === 4 || item.id === 5);
      default:
        return [];
    }
  };

  const groupInputControls = (): IGroupInputControls[] => {
    return [
      {
        title: 'General Information:',
        inputControls: [
          {
            fieldKey: 'id',
            type: EDITOR_TYPES.TEXT_FIELD,
            isHidden: true,
          },
          {
            fieldKey: 'refDataId',
            type: EDITOR_TYPES.TEXT_FIELD,
            isHidden: true,
          },
          {
            fieldKey: 'bulletinType',
            type: EDITOR_TYPES.DROPDOWN,
            options: noteTypes,
            isFullFlex: true,
          },
          {
            fieldKey: 'bulletinLevel',
            type: EDITOR_TYPES.DROPDOWN,
            options: filterDefaultLevels(),
            isFullFlex: true,
            isDisabled: params.upsert.toUpperCase() === VIEW_MODE.EDIT ? true : false,
          },
          {
            fieldKey: 'startDate',
            type: EDITOR_TYPES.DATE,
            maxDate: useUpsert.getField('endDate').value,
          },
          {
            fieldKey: 'endDate',
            type: EDITOR_TYPES.DATE,
            minDate: useUpsert.getField('startDate').value,
            customErrorMessage: bulletinStore.isEndDateRequired ? 'The End Date* field is required.' : '',
            isDisabled: Boolean(useUpsert.getField('isUFN').value),
          },
          {
            fieldKey: 'isUFN',
            type: EDITOR_TYPES.CHECKBOX,
          },
          {
            fieldKey: 'notamNumber',
            type: EDITOR_TYPES.TEXT_FIELD,
            isFullFlex: true,
          },
          {
            fieldKey: 'bulletinText',
            type: EDITOR_TYPES.TEXT_FIELD,
            multiline: true,
            isFullFlex: true,
            rows: 6,
            showExpandButton: false,
            customErrorMessage: !bulletinStore.isNotesTextExist ? 'The Notes Text* field is required.' : '',
          },
        ],
      },
    ];
  };

  const htmltoText = (html: string) => {
    let text = html;
    text = text?.replace(/\n/gi, '');
    text = text?.replace(/<style([\s\S]*?)<\/style>/gi, '');
    text = text?.replace(/<script([\s\S]*?)<\/script>/gi, '');
    text = text?.replace(/<a.*?href='(.*?)[\\?\\'].*?>(.*?)<\/a.*?>/gi, ' $2 $1 ');
    text = text?.replace(/<\/div>/gi, '\n\n');
    text = text?.replace(/<\/li>/gi, '\n');
    text = text?.replace(/<li.*?>/gi, '  *  ');
    text = text?.replace(/<\/ul>/gi, '\n\n');
    text = text?.replace(/<\/p>/gi, '\n\n');
    text = text?.replace(/<br\s*[\\/]?>/gi, '\n');
    text = text?.replace(/<[^>]+>/gi, '');
    text = text?.replace(/^\s*/gim, '');
    text = text?.replace(/ ,/gi, ',');
    text = text?.replace(/ +/gi, ' ');
    text = text?.replace(/\n+/gi, '\n\n');
    return text;
  };

  const onValueChange = (value: IOptionValue, fieldKey: string): void => {
    useUpsert.getField(fieldKey).set(value);
    switch (fieldKey) {
      case 'startDate':
        if (useUpsert.getField('isUFN').value) {
          bulletinStore.isEndDateRequired = false;
        } else if (!useUpsert.getField('isUFN').value) {
          if (useUpsert.getField('endDate').value) {
            bulletinStore.isEndDateRequired = false;
          } else {
            bulletinStore.isEndDateRequired = true;
          }
        }
        if (htmltoText(useUpsert.getField('bulletinText').value.toString())) {
          bulletinStore.isNotesTextExist = true;
        } else {
          bulletinStore.isNotesTextExist = false;
        }
        break;
      case 'isUFN':
        if (value) {
          useUpsert.getField('endDate').set('label', 'End Date');
          useUpsert.getField('endDate').set(null);
        } else {
          useUpsert.getField('endDate').set('label', 'End Date*');
        }
        if (!useUpsert.getField('endDate').value && !value) {
          bulletinStore.isEndDateRequired = true;
        } else {
          bulletinStore.isEndDateRequired = false;
        }
        break;
      case 'endDate':
        if (value) {
          if (!useUpsert.getField('isUFN').value) bulletinStore.isEndDateRequired = false;
        } else if (!useUpsert.getField('isUFN').value) {
          bulletinStore.isEndDateRequired = true;
        }
        break;
      case 'bulletinText':
        if (htmltoText(value.toString())) {
          bulletinStore.isNotesTextExist = true;
        } else {
          bulletinStore.isNotesTextExist = false;
        }
        break;
      default:
        break;
    }
    gridState.hasError = Utilities.hasInvalidRowData(gridState.gridApi);
  };

  const onSearch = (searchValue: string, fieldKey: string): void => {
    switch (fieldKey) {
      case 'bulletinType':
        const filteredTypes = settingsStore.bulletinTypes?.filter(data => {
          return data.label?.toLowerCase().includes(searchValue.toLowerCase());
        });
        setNoteTypes(filteredTypes);
        break;
      case 'bulletinLevel':
        const filteredLevels = noteLevels?.filter(data => {
          return data.label?.toLowerCase().includes(searchValue.toLowerCase());
        });
        setAirportLevels(filteredLevels);
        break;
      default:
        break;
    }
    return;
  };

  const onFocus = (fieldKey: string): void => {
    switch (fieldKey) {
      case 'startDate':
        if (useUpsert.getField('isUFN').value) {
          if (!useUpsert.getField('isUFN').value) bulletinStore.isEndDateRequired = false;
        } else if (!useUpsert.getField('isUFN').value) {
          bulletinStore.isEndDateRequired = true;
        }
        if (htmltoText(useUpsert.getField('bulletinText').value.toString())) {
          bulletinStore.isNotesTextExist = true;
        } else {
          bulletinStore.isNotesTextExist = false;
        }
        break;
      case 'isUFN':
        if (useUpsert.getField(fieldKey).value) {
          useUpsert.getField('endDate').set('label', 'End Date');
        } else {
          useUpsert.getField('endDate').set('label', 'End Date*');
        }
        if (!useUpsert.getField('endDate').value && !useUpsert.getField(fieldKey).value) {
          bulletinStore.isEndDateRequired = true;
        } else {
          bulletinStore.isEndDateRequired = false;
        }
        break;
      case 'endDate':
        if (useUpsert.getField(fieldKey).value) {
          if (!useUpsert.getField('isUFN').value) bulletinStore.isEndDateRequired = false;
        } else if (!useUpsert.getField('isUFN').value) {
          bulletinStore.isEndDateRequired = true;
        }
        break;
      case 'bulletinText':
        if (htmltoText(useUpsert.getField(fieldKey).value?.toString())) {
          bulletinStore.isNotesTextExist = true;
        } else {
          bulletinStore.isNotesTextExist = false;
        }
        break;
      default:
        break;
    }
  };

  const loadInitialData = () => {
    UIStore.setPageLoader(true);
    bulletinStore
      .getLocationBulletin(params?.id)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => {
          UIStore.setPageLoader(false);
        })
      )
      .subscribe((response: HandlerBulletinsModel) => {
        useUpsert.setFormValues(HandlerBulletinsModel.deserialize(response));
        useUpsert.getField('bulletinType').set(response.bulletinType);
        if (response.isUFN) {
          bulletinStore.isEndDateRequired = false;
          useUpsert.getField('endDate').set('label', 'End Date');
        } else {
          if (!useUpsert.getField('endDate').value) {
            bulletinStore.isEndDateRequired = true;
          } else {
            bulletinStore.isEndDateRequired = false;
          }
          useUpsert.getField('endDate').set('label', 'End Date*');
        }
        if (response.bulletinText) {
          bulletinStore.isNotesTextExist = true;
        } else {
          bulletinStore.isNotesTextExist = false;
        }
      });
  };

  const loadAirportData = () => {
    UIStore.setPageLoader(true);
    const request: IAPIGridRequest = {
      pageNumber: gridState.pagination.pageNumber,
      pageSize: gridState.pagination.pageSize,
      filterCollection: JSON.stringify([
        {
          propertyName: 'AirportId',
          propertyValue: RootDataStore.locationData.airportReferenceId,
        },
      ]),
    };
    vendorLocationStore
      .getVmsIcaoCode(request)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => {
          UIStore.setPageLoader(false);
        })
      )
      .subscribe();
  };

  const loadLocationAddress = () => {
    UIStore.setPageLoader(true);
    const cityAddressRequest: IAPIGridRequest = {
      pageNumber: gridState.pagination.pageNumber,
      pageSize: gridState.pagination.pageSize,
      filterCollection: JSON.stringify([
        {
          propertyName: 'VendorLocation.VendorLocationId',
          propertyValue: RootDataStore.locationData.locationId,
        },
      ]),
    };
    bulletinStore
      .getVMSComparison(COLLECTION_NAMES.VENDOR_LOCATION_ADDRESS, cityAddressRequest)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => {
          UIStore.setPageLoader(false);
        })
      )
      .subscribe();
  };
  const headerTitle = () => {
    if (params.upsert.toUpperCase() === VIEW_MODE.NEW) return 'Add New Note';
    else if (params.upsert.toUpperCase() === VIEW_MODE.EDIT) return 'Edit Note';
    return 'Note Details';
  };

  const updateViewMode = () => {
    useUpsert.setViewMode(VIEW_MODE.EDIT);
    navigate(`/vendor/location/${params.type}/notes/edit/${params?.id}`);
  };

  const isButtonDisable = () => {
    if (params.upsert.toUpperCase() === VIEW_MODE.DETAILS) {
      return false;
    }
    return (
      !formRef.changed ||
      !formRef.isValid ||
      formRef.hasError ||
      bulletinStore.isEndDateRequired ||
      !bulletinStore.isNotesTextExist
    );
  };

  const headerActions = (): ReactNode => {
    return (
      <>
        <Typography variant="h5" style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
          {<CustomTooltip title={headerTitle()} />}
        </Typography>
        <Box sx={{ display: 'flex' }}>
          <div className={`${classes.defaultButton}`}>
            <Button color="primary" variant="outlined" onClick={() => getConfirmation()} size="large">
              {params.upsert.toUpperCase() === VIEW_MODE.DETAILS ? 'Back to List' : 'Cancel'}
            </Button>
          </div>
          <div className={`${classes.primaryButton} ${classes.defaultButton}`}>
            <Button
              color="primary"
              variant="contained"
              onClick={() =>
                params.upsert.toUpperCase() === VIEW_MODE.DETAILS ? updateViewMode() : upsertAirportBulletin()
              }
              size="large"
              disabled={isButtonDisable()}
            >
              {params.upsert.toUpperCase() === VIEW_MODE.DETAILS ? 'Edit' : 'Save'}
            </Button>
          </div>
        </Box>
      </>
    );
  };

  const errorHandler = (errors: object, id): void => {
    Object.values(errors)?.forEach(errorMessage => useUpsert.showAlert(errorMessage[0], id));
  };

  const upsertAirportBulletin = (): void => {
    UIStore.setPageLoader(true);
    const request = new HandlerBulletinsModel({ ...useUpsert.form.values() });
    const isBulletinLevelLocation = request.bulletinLevel.id === 2 && isHandler;
    const isBulletinLevelAirport = request.bulletinLevel.id === 1 && isAirport;
    const locationData = RootDataStore.locationData;
    const airportData = vendorLocationStore.airportList[0];
    const locationAddress = bulletinStore.locationAddress && bulletinStore.locationAddress[0];
    const entityValue = VendorLocationModel.deserialize({
      id: HandlerBulletinsModel.getBulletinEntityId(
        isHandler,isAirport,isCountry,request.bulletinLevel.id,locationAddress
      ),

      code: HandlerBulletinsModel.getBulletinEntityCode(
        isHandler,isAirport,isCountry,request.bulletinLevel.id,locationAddress,airportData
      ),

      name: HandlerBulletinsModel.getBulletinEntityName(
        isHandler,isAirport,isCountry,request.bulletinLevel.id,locationAddress,airportData
      ),
    });
    bulletinStore
      ?.upsertVendorLocationHanlderBulletin(request.serialize(entityValue, MODEL_STATUS.ACTIVE))
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe({
        next: (response: HandlerBulletinsModel) => {
          useUpsert.form.reset();
          useUpsert.resetFormValidations(response, () => {
            navigate(`/vendor/location/${params.type}/notes`);
          });
        },
        error: error => {
          errorHandler(error.response?.data?.errors, request.id.toString());
        },
      });
  };

  const getConfirmation = (): void => {
    if (formRef.changed) {
      ModalStore.open(
        <CustomDialog
          title="Confirm changes"
          message={'Canceling will lost your changes. Are you sure you want to cancel?'}
          yesButton="Confirm"
          noButton="Cancel"
          onNoClick={() => ModalStore.close()}
          onYesClick={() => {
            navigate(`/vendor/location/${params.type}/notes`);
          }}
        />
      );
    } else {
      navigate(`/vendor/location/${params.type}/notes`);
    }
  };

  return (
    <DetailsEditorWrapper
      headerActions={headerActions()}
      isEditMode={true}
      classes={{ headerActions: classes.headerActions }}
    >
      <div className={classes.editorWrapperContainer}>
        <ViewInputControls
          isEditable={isEditable}
          groupInputControls={groupInputControls()}
          onGetField={(fieldKey: string) => useUpsert.getField(fieldKey)}
          onValueChange={(option, fieldKey) => onValueChange(option, fieldKey)}
          field={fieldKey => useUpsert.getField(fieldKey)}
          onSearch={(searchValue: string, fieldKey: string) => onSearch(searchValue, fieldKey)}
          onFocus={fieldKey => onFocus(fieldKey)}
        />
      </div>
    </DetailsEditorWrapper>
  );
};

export default inject(
  'bulletinStore',
  'settingsStore',
  'vendorLocationStore'
)(withStyles(styles)(observer(UpsertNotes)));
