import { observable, action } from 'mobx';
import {
  COLLECTION_NAMES,
  HandlerBulletinsModel,
  IAPIVMSVendorLocationComparison,
  VendorLocationAddressModel,
} from '../Modules/Shared';
import { IAPIGridRequest, IAPIPageResponse, Utilities } from '@wings-shared/core';
import { Observable } from 'rxjs';
import { baseApiPath, HttpClient } from '@uplink/shared';
import { vendorManagementHeaders } from './Base.store';
import { apiUrls } from './API.url';
import { Logger } from '@uplink-shared/security';
import { map, tap } from 'rxjs/operators';
import { AlertStore } from '@uvgo-shared/alert';

export class BulletinStore {
  @observable public handlerBulletinsList: HandlerBulletinsModel[] = [];
  @observable public isEndDateRequired: boolean = false;
  @observable public isNotesTextExist: boolean = true;
  @observable public locationAddress: VendorLocationAddressModel[] = [];

  @action
  public getLocationBulletins(pageRequest?: IAPIGridRequest): Observable<IAPIPageResponse<HandlerBulletinsModel>> {
    const http: HttpClient = new HttpClient({
      baseURL: baseApiPath.vendorManagementNoSqlUrl,
      headers: vendorManagementHeaders,
    });
    const params: string = Utilities.buildParamString({
      CollectionName: 'Bulletin',
      ...pageRequest,
    });

    return http.get<IAPIPageResponse<HandlerBulletinsModel>>(`/${apiUrls.vendorManagement}?${params}`).pipe(
      Logger.observableCatchError,

      map(response => {
        this.handlerBulletinsList = HandlerBulletinsModel.deserializeList(response.results);
        return { ...response, results: this.handlerBulletinsList };
      })
    );
  }

  @action
  public getVMSComparison(
    collectionName: COLLECTION_NAMES,
    pageRequest?: IAPIGridRequest
  ): Observable<IAPIPageResponse<VendorLocationAddressModel>> {
    const http: HttpClient = new HttpClient({
      baseURL: baseApiPath.vendorManagementNoSqlUrl,
      headers: vendorManagementHeaders,
    });
    const params: string = Utilities.buildParamString({
      CollectionName: collectionName,
      ...pageRequest,
    });

    return http.get<IAPIPageResponse<IAPIVMSVendorLocationComparison>>(`${apiUrls.vendorManagement}?${params}`).pipe(
      Logger.observableCatchError,

      map(response => {
        this.locationAddress = VendorLocationAddressModel.deserializeList(response.results);
        this.locationAddress = this.locationAddress && this.locationAddress.filter(item => {
          return item.addressType.id === 5
        })
        return { ...response, results: this.locationAddress };
      })
    );
  }

  @action
  public getLocationAllBulletins(pageRequest?: IAPIGridRequest): Observable<IAPIPageResponse<HandlerBulletinsModel>> {
    const http: HttpClient = new HttpClient({
      baseURL: baseApiPath.vendorManagementNoSqlUrl,
      headers: vendorManagementHeaders,
    });
    const params: string = Utilities.buildParamString({
      CollectionName: 'Bulletin',
      ...pageRequest,
    });

    return http.get<IAPIPageResponse<HandlerBulletinsModel>>(`/${apiUrls.vendorManagement}?${params}`).pipe(
      Logger.observableCatchError,

      map(response => {
        this.handlerBulletinsList = HandlerBulletinsModel.deserializeList(response.results);
        return { ...response, results: this.handlerBulletinsList };
      })
    );
  }

  @action
  public getLocationBulletin(id: number): Observable<IAPIPageResponse<HandlerBulletinsModel>> {
    const http: HttpClient = new HttpClient({
      baseURL: baseApiPath.vendorManagementCoreUrl,
      headers: vendorManagementHeaders,
    });
    return http.get<IAPIPageResponse<HandlerBulletinsModel>>(`/${apiUrls.bulletin}/${id}`).pipe(
      Logger.observableCatchError,
      map(response => HandlerBulletinsModel.deserialize(response))
    );
  }

  @action
  public upsertVendorLocationHanlderBulletin(payload: HandlerBulletinsModel): Observable<HandlerBulletinsModel> {
    const http = new HttpClient({ headers: vendorManagementHeaders });
    payload.id = payload.id === null ? 0 : payload.id;
    const isNewRequest: boolean = !Boolean(payload.id);
    const upsertRequest: Observable<HandlerBulletinsModel> = isNewRequest
      ? http.post<any>(`${baseApiPath.vendorManagementCoreUrl}/${apiUrls.bulletin}`, payload)
      : http.put<any>(`${baseApiPath.vendorManagementCoreUrl}/${apiUrls.bulletin}/${payload.id}`, payload);
    return upsertRequest.pipe(
      Logger.observableCatchError,
      tap(() => {
        if(payload.statusId === 1){
          AlertStore.info('Note saved successfully!')
        } else {
          AlertStore.info('Note deleted successfully!')
        }
      }),
      map(response => HandlerBulletinsModel.deserialize(response))
    );
  }
}
