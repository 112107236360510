import { createStyles, Theme } from '@material-ui/core';

export const styles = createStyles((theme:Theme) => ({
  defaultButton: {
    '& button': {
      padding: '4px 10px',
      height: '40px',
      borderRadius: '4px',
      fontSize: '14px',
      fontWeight: '400',
      minWidth: '100px',
      color: '#1976D2',
      textTransform: 'capitalize'
    },
  },
  primaryButton: {
    '& button': {
      background: '#1976D2',
      color: '#fff',
      minWidth: '100px',
      height: '40px',
      marginLeft: '10%',
      textTransform: 'capitalize'
    },
    '& button:disabled': {
      background: '#afafaf',
      border: 'none',
      color: 'white',
    },
  },
  headerLabel:{
    fontSize: '14px',
  },
  labelRoot: {
    fontSize: '12px',
    fontWeight: 600,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  textInput: {
    '& .MuiOutlinedInput-input':{
      minHeight: '40px'
    }
  }
}));
