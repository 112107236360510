import { IAPISearchFiltersDictionary, IClasses, IOptionValue, Loader, UIStore, Utilities } from '@wings-shared/core';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import React, { FC, ReactNode, useEffect, useState } from 'react';
import { NavigateFunction, useLocation, useNavigate, useParams } from 'react-router';
import { inject, observer } from 'mobx-react';
import { Box, IconButton, Typography, withStyles } from '@material-ui/core';
import { styles } from './UpsertDocument.styles';
import { MixPanelTrackingEvents, useBaseUpsertComponent } from '@uplink/shared';
import { useUnsubscribe } from '@wings-shared/hooks';
import { fields } from './Fields';
import { EDITOR_TYPES, IGroupInputControls } from '@uplink-shared/form-controls';
import { finalize, takeUntil } from 'rxjs/operators';
import { DetailsEditorWrapper, SidebarStore, ConfirmNavigate } from '@uplink-shared/layout';
import Button from '@material-ui/core/Button';
import { PrimaryButton } from '@uvgo-shared/buttons';
import {
  SETTING_ID,
  VendorDocumentModel,
  SidebarMenus,
  ViewInputControls,
  UploadDocumentFile,
  COLLECTION_NAMES,
} from '../../Shared';
import { SettingsStore, VendorDocumentStore } from '../../../Stores';
import { forkJoin } from 'rxjs';
import { SettingNamesMapper } from '../../../Stores/SettingsMapper';
import moment from 'moment';
import { CloseIcon, UploadIcon } from '@uvgo-shared/icons';
import { PROGRESS_TYPES } from '@uvgo-shared/progress';
import { IAPIDocumentFile, IAPIDownloadDocumentFile } from '../../Shared/Interfaces/Request/API-Request-VendorDocument';
import { AuthStore } from '@uplink-shared/security';
import { AlertStore } from '@uvgo-shared/alert';
import { AnalyticsStore } from '@uplink-shared/analytics';

interface Props {
  classes?: IClasses;
  settingsStore: SettingsStore;
  vendorDocumentStore: VendorDocumentStore;
  searchFilters?: IAPISearchFiltersDictionary;
  navigate?: NavigateFunction;
}

const UpsertDocument: FC<Props> = ({ classes, settingsStore, vendorDocumentStore, searchFilters }) => {
  const unsubscribe = useUnsubscribe();
  const useUpsert = useBaseUpsertComponent<VendorDocumentModel>({}, fields, searchFilters);
  const formRef = useUpsert.form;
  const navigate = useNavigate();
  const params = useParams();
  const progressLoader: Loader = new Loader(false, { type: PROGRESS_TYPES.CIRCLE });
  const [ isSaved, setIsSaved ] = useState(false);

  const getBasePath = (): string => {
    if (params.id) {
      return `vendor/documents/upsert/${params.id}/`;
    }
    return 'vendor/documents/upsert/';
  };

  useEffect(() => {
    AnalyticsStore.track(MixPanelTrackingEvents.VENDOR_DOCUMENT);
    SidebarStore.setNavLinks(SidebarMenus(), getBasePath());
    loadInitialData();
    vendorDocumentStore.documentUpdated = false;
    vendorDocumentStore.file = null;
    vendorDocumentStore.documentRemove = false;
  }, []);

  const title = (): string => {
    return params.id ? 'Update Document' : 'Add Documents';
  };

  const groupInputControls = (): IGroupInputControls[] => {
    return [
      {
        title: '',
        inputControls: [
          {
            fieldKey: 'id',
            type: EDITOR_TYPES.TEXT_FIELD,
            isHidden: true,
          },
          {
            fieldKey: 'name',
            type: EDITOR_TYPES.DROPDOWN,
            options: settingsStore.vendorDocumentName,
          },
          {
            fieldKey: 'otherName',
            type: EDITOR_TYPES.TEXT_FIELD,
            isDisabled: !Boolean(useUpsert.getField('name').value?.name?.toLocaleLowerCase() === 'other'),
            customErrorMessage: vendorDocumentStore.isOtherNameRequired && 'The Other Name* field is required.',
          },
          {
            fieldKey: 'status',
            type: EDITOR_TYPES.DROPDOWN,
            options: settingsStore.vendorSettings,
            isHidden: true,
          },
          {
            fieldKey: 'documentUri',
            type: EDITOR_TYPES.TEXT_FIELD,
            isHidden: true,
          },
          {
            fieldKey: 'startDate',
            type: EDITOR_TYPES.DATE,
          },
          {
            fieldKey: 'endDate',
            type: EDITOR_TYPES.DATE,
          },
          {
            fieldKey: 'comment',
            type: EDITOR_TYPES.TEXT_FIELD,
            isHidden: true,
          },
          {
            fieldKey: 'lastUpdated',
            type: EDITOR_TYPES.TEXT_FIELD,
            isHidden: true,
          },
        ],
      },
    ];
  };

  const onValueChange = (value: IOptionValue, fieldKey: string): void => {
    useUpsert.getField(fieldKey).set(value);
    vendorDocumentStore.documentUpdated = true;
    switch (fieldKey) {
      case 'name':
        const docName = useUpsert.getField('name').value?.name;
        if (docName?.toLocaleLowerCase() !== 'other') {
          useUpsert.getField('otherName').set('');
          useUpsert.getField('otherName').set('label', 'Other Name');
          vendorDocumentStore.isOtherNameRequired = false;
        } else {
          useUpsert.getField('otherName').set('label', 'Other Name*');
          vendorDocumentStore.isOtherNameRequired = true;
        }
        break;
      case 'startDate':
        const startDate = moment(useUpsert.getField('startDate').value);
        const endDate = moment(useUpsert.getField('endDate').value);
        if (startDate.utc().isSameOrAfter(endDate.utc(), 'day')) {
          useUpsert.getField('endDate').set(null);
        }
        break;
      case 'endDate':
        const startDatee = moment(useUpsert.getField('startDate').value);
        const endDatee = moment(useUpsert.getField('endDate').value);
        if (endDatee.utc().isSameOrBefore(startDatee.utc(), 'day')) {
          useUpsert.showAlert('Expiration Date cannot be less than or equal to Start Date', '0');
          useUpsert.getField('endDate').set(null);
        }
        break;
      case 'otherName':
        if (value) {
          vendorDocumentStore.isOtherNameRequired = false;
        } else {
          useUpsert.getField('otherName').set('label', 'Other Name*');
          vendorDocumentStore.isOtherNameRequired = true;
        }
        break;
      default:
        break;
    }
  };

  const onSearch = (searchValue: string, fieldKey: string): void => {
    switch (fieldKey) {
      default:
        break;
    }
    return;
  };

  const onFocus = (fieldKey: string): void => {
    switch (fieldKey) {
      case 'name':
        settingsStore.getSettings(SETTING_ID.SETTING_DOCUMENT_NAME).subscribe();
        break;
      default:
        break;
    }
  };

  const loadInitialData = () => {
    if (params.id) {
      UIStore.setPageLoader(true);
      forkJoin([
        vendorDocumentStore?.getVendorDocumentById(parseInt(params.id)),
        settingsStore.getSettings(SETTING_ID.SETTING_DOCUMENT_NAME),
      ])
        .pipe(
          takeUntil(unsubscribe.destroy$),
          finalize(() => UIStore.setPageLoader(false))
        )
        .subscribe((response: [VendorDocumentModel]) => {
          setFormValues(response[0]);
        });
    }
  };

  const resetValues = () => {
    vendorDocumentStore.documentUpdated = false;
    vendorDocumentStore.isOtherNameRequired = false;
    vendorDocumentStore.file = null;
  };

  const dialogHeader = (): ReactNode => {
    return params.id ? 'Edit Document' : 'Add Document';
  };

  const headerActions = (): ReactNode => {
    return (
      <>
        <Typography variant="h5">{dialogHeader()}</Typography>
        <Box sx={{ display: 'flex' }}>
          <div className={`${classes.defaultButton}`}>
            <Button color="primary" variant="outlined" onClick={() => navigate(-1)} size="large">
              Cancel
            </Button>
          </div>
          <div className={`${classes.primaryButton} ${classes.defaultButton}`}>
            <Button
              color="primary"
              variant="contained"
              onClick={() => upsertDocument()}
              size="large"
              disabled={
                !formRef.isValid ||
                formRef.hasError ||
                vendorDocumentStore.isOtherNameRequired ||
                !vendorDocumentStore.documentUpdated
              }
            >
              Save
            </Button>
          </div>
        </Box>
      </>
    );
  };

  const setFormValues = response => {
    resetValues();
    useUpsert.setFormValues(response);
  };

  const upsertDocument = (): void => {
    UIStore.setPageLoader(true);
    if (!isSaved) {
      const request = new VendorDocumentModel({ ...useUpsert.form.values() });
      setIsSaved(true);
      vendorDocumentStore
        ?.upsertDocument(request.serialize(vendorDocumentStore.documentUri, AuthStore?.vendorProfile?.id, 0, 0))
        .pipe(
          takeUntil(unsubscribe.destroy$),
          finalize(() => UIStore.setPageLoader(false))
        )
        .subscribe({
          next: (response: VendorDocumentModel) => {
            setIsSaved(false);
            resetValues();
            useUpsert.form.reset();
            setFormValues(response);
            vendorDocumentStore.documentRemove = false;
            useUpsert.resetFormValidations(response, () => {
              navigate('/vendor/documents');
              vendorDocumentStore.hasDataLoaded = true;
            });
          },
          error: error => {
            setIsSaved(false);
            if (error.response.data.errors) {
              errorHandler(error.response.data.errors, request.id.toString());
              return;
            }
            useUpsert.showAlert(error.message, request.id.toString());
          },
        });
    }
  };

  const errorHandler = (errors: object, id): void => {
    Object.values(errors)?.forEach(errorMessage => useUpsert.showAlert(errorMessage[0], id));
  };

  const uploadDocumentFile = (): void => {
    UIStore.setPageLoader(true);
    progressLoader.setLoadingState(true);
    vendorDocumentStore
      ?.importDocumentFile(COLLECTION_NAMES.VENDOR_DOCUMENT, vendorDocumentStore.file[0], AuthStore?.vendorProfile?.id)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => {
          UIStore.setPageLoader(false);
          progressLoader.setLoadingState(false);
        })
      )
      .subscribe({
        next: (response: IAPIDocumentFile) => {
          if (response) {
            vendorDocumentStore.documentUpdated = true;
            vendorDocumentStore.documentUri = response.results;
            vendorDocumentStore.documentRemove = false;
            useUpsert.getField('documentUri').set(response.results);
            useUpsert.showAlert('Vendor Document File imported successfully', '0');
            ModalStore.close();
          }
        },
        error: error => {
          if (error.response.data.errors) {
            errorHandler(error.response.data.errors, '0');
            return;
          }
          useUpsert.showAlert(`Records imported with errors ${error.message}`, '0');
        },
      });
  };

  const onRequestImportDocument = (): void => {
    ModalStore.open(
      <UploadDocumentFile
        fileType=".doc, .docx, .pdf, .jpg, .jpeg, .png, .ppt, .pptx"
        title="Upload Document File"
        uploadDocumentFile={() => uploadDocumentFile()}
        loader={progressLoader}
      />
    );
  };

  const downloadFile = (data: VendorDocumentModel) => {
    UIStore.setPageLoader(true);
    vendorDocumentStore
      ?.downloadDocumentFile(data.documentUri, data.id, AuthStore?.vendorProfile?.id)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => {
          UIStore.setPageLoader(false);
        })
      )
      .subscribe({
        next: (response: IAPIDownloadDocumentFile) => {
          // const url = window.URL.createObjectURL(new Blob([ documentUploadStore.file ]));
          const link = document.createElement('a');
          link.href = response.documentUri;
          link.target = '_blank';
          if (data.otherName !== null) {
            link.download = data.otherName;
          } else {
            link.download = data.name.name;
          }
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        },
        error: error => {
          AlertStore.info(`Error Downloading ${error.message}`);
        },
      });
  };

  return (
    <ConfirmNavigate isBlocker={formRef.changed || vendorDocumentStore.documentUpdated}>
      <DetailsEditorWrapper
        headerActions={headerActions()}
        isEditMode={true}
        classes={{ headerActions: classes.headerActions }}
      >
        <div className={classes.editorWrapperContainer}>
          <ViewInputControls
            isEditable={true}
            groupInputControls={groupInputControls()}
            onGetField={(fieldKey: string) => useUpsert.getField(fieldKey)}
            onValueChange={(option, fieldKey) => onValueChange(option, fieldKey)}
            field={fieldKey => useUpsert.getField(fieldKey)}
            onSearch={(searchValue: string, fieldKey: string) => onSearch(searchValue, fieldKey)}
            onFocus={fieldKey => onFocus(fieldKey)}
          />
          <div className={`${classes.primaryButton} ${classes.customButton}`}>
            <PrimaryButton
              variant="contained"
              color="primary"
              className={classes.primaryButton}
              startIcon={<UploadIcon />}
              onClick={onRequestImportDocument}
            >
              Upload Document
            </PrimaryButton>
          </div>
          {!vendorDocumentStore.documentRemove &&
            (vendorDocumentStore.file || useUpsert.getField('documentUri')?.value) && (
            <Box className={classes.fileBox}>
              <Typography
                onClick={() =>
                    useUpsert.getField('id')?.value &&
                    downloadFile(new VendorDocumentModel({ ...useUpsert.form.values() }))
                }
              >
                {vendorDocumentStore.file
                  ? `${vendorDocumentStore.file[0].name}`
                  : useUpsert.getField('documentUri')?.value && useUpsert.getField('documentUri')?.value}
              </Typography>
              <IconButton
                onClick={() => {
                  vendorDocumentStore.file = null;
                  vendorDocumentStore.documentUpdated = false;
                  vendorDocumentStore.documentRemove = true;
                }}
                style={{
                  color: '#1976D2',
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          )}
        </div>
      </DetailsEditorWrapper>
    </ConfirmNavigate>
  );
};

export default inject('settingsStore', 'vendorDocumentStore')(withStyles(styles)(observer(UpsertDocument)));
