import { createStyles, Theme } from '@material-ui/core';

export const styles = createStyles((theme: Theme) => ({
  formAddress: {
    marginBottom: '-20px !important',
    '& .MuiInputLabel-outlined': {
      fontSize: '14px !important',
    },
  },
}));
