import React, { ReactNode, useState } from 'react';
import { Box, Modal, Paper } from '@material-ui/core';
import { useStyles } from './DataExpiryCard.styles';
import { RootDataStore } from '@uplink-shared/layout';
import { PrimaryButton } from '@uvgo-shared/buttons';
import { inject, observer } from 'mobx-react';
import { useNavigate } from 'react-router';
import { UIStore } from '@wings-shared/core';
import { useUnsubscribe } from '@wings-shared/hooks';
import { finalize, takeUntil } from 'rxjs/operators';
import { ApprovalDataModel, OperationalEssentialsModel, VendorLocationModel } from '../../../../Shared/Models';
import { BaseStore, DataExpiryStore, VendorLocationStore } from '../../../../../Stores';
import CardModal from '../CardModal';
import { EDITOR_TYPES } from '@uplink-shared/form-controls';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import { EditIcon } from '@uvgo-shared/icons';
import LaunchIcon from '@material-ui/icons/LaunchRounded';
import { ENTITY_NAMES, VALIDATION_REGEX } from '../../../../Shared';
import ExpiryQuestionaire from '../../../../Shared/Components/Questionaire/ExpiryQuestionaire';
import { DataExpirationUptoDate } from '../../../../Shared/Models/DataExpirationUptoDate.model';
import { AuthStore } from '@uplink-shared/security';
import { ApprovalDataStore } from '../../../../../Stores/ApprovalData.store';

interface Props {
  dataExpiryStore?: DataExpiryStore;
  vendorLocationStore: VendorLocationStore;
  approvalDataStore: ApprovalDataStore;
}

interface DataExpiryCard {
  card: number;
  title: string;
  buttonText: string;
  component: any;
  redirectUrl: string;
  sectionTypeName: string;
  vendorLocation: VendorLocationModel;
  dataExpSectionTypeId: number;
  vendorLocationId: number;
}

const DataExpiryCard: React.FC<Props> = ({ dataExpiryStore, vendorLocationStore, approvalDataStore }) => {
  const classes = useStyles();
  const unsubscribe = useUnsubscribe();
  const navigate = useNavigate();
  const [ isModalOpen, setModalOpen ] = useState(false);
  const [ modalProps, setModalProps ] = useState<DataExpiryCard>(null);

  const profileEndAdornment = (): ReactNode => {
    return <LaunchIcon className={classes.buttonStyle} onClick={() => ModalStore.open(<ExpiryQuestionaire />)} />;
  };

  const sectionTypeData = {
    VendorAddress: { name: 'Addresses', url: '/vendor/addresses' },
    VendorContact: { name: 'Contacts', url: '/vendor/contact' },
    VendorDocument: { name: 'Documents', url: '/vendor/documents' },
    VendorLocationAddress: { name: 'Addresses', url: '/vendor/location/addresses' },
    VendorLocationContact: { name: 'Contacts', url: '/vendor/location/contact' },
    VendorLocationDocument: { name: 'Documents', url: '/vendor/location/documents' },
    VendorLocationServiceComm: { name: 'Service Comm', url: '/vendor/location/operational-essential' },
    ServiceItemPricing: { name: 'Pricing', url: '/vendor/location/pricing' },
    OperationType: {
      name: 'Operation Type',
      url: '',
      showEditButton: true,
      type: EDITOR_TYPES.TEXT_FIELD,
      endAdormentValue: profileEndAdornment(),
      rules: '',
      isEditable: false,
      fieldName: 'appliedOperationType',
    },
    ManagerName: {
      name: 'Manager Name',
      url: '',
      showEditButton: true,
      type: EDITOR_TYPES.TEXT_FIELD,
      rules: 'string|between:2,100',
      isEditable: true,
      fieldName: 'managerName',
    },
    AsstManagerName: {
      name: 'Asst Manager Name',
      url: '',
      showEditButton: true,
      type: EDITOR_TYPES.TEXT_FIELD,
      rules: 'string|between:2,100',
      isEditable: true,
      fieldName: 'asstManagerName',
    },
    AirToGroundFrequency: {
      name: 'Air To Ground Frequency',
      url: '',
      showEditButton: true,
      type: EDITOR_TYPES.TEXT_FIELD,
      rules: 'numeric|between:0,999.99999|regex:/^\\d{1,3}(\\.\\d{1,5})?$/',
      options: [],
      isEditable: true,
      fieldName: 'airToGroundFrequency',
    },
  };

  const getCardData = sectionType => sectionTypeData[sectionType] || { name: '', url: '' };

  const loadVendorLocationData = parseState => {
    UIStore.setPageLoader(true);
    vendorLocationStore
      ?.getVendorLocationById(parseState.vendorLocation.vendorLocationId)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe((response: VendorLocationModel) => {
        if (response) {
          RootDataStore.setLocation(
            true,
            response.id,
            response.name,
            response.code,
            response.airportReference?.id,
            '',
            response.airportDataManagement,
            response.countryDataManagement,
            response.permitDataManagement
          );

          navigate(getCardData(parseState.sectionType.name)?.url, {
            state: parseState,
          });
        }
      });
  };

  const daysLeftUntilExpiry = lastExpiryDate => {
    const currentDate = new Date();
    const expiryDate = new Date(lastExpiryDate);

    const differenceInMilliseconds = expiryDate - currentDate;

    const differenceInDays = Math.ceil(differenceInMilliseconds / (1000 * 60 * 60 * 24));
    if (differenceInDays <= 7) return 'red';
    if (differenceInDays > 7 && differenceInDays <= 30) return 'yellow';
  };

  const errorHandler = (errors: object, id): void => {
    Object.values(errors)?.forEach(errorMessage => BaseStore.showAlert(errorMessage[0], id));
  };

  const handleSave = (newValue, sectionTypeId, item, fieldName): void => {
    if (fieldName === 'appliedOperationType') {
      newValue = [
        {
          id: 0,
          operationTypeId: dataExpiryStore?.operationTypeId,
        },
      ];
      item.fieldValue = [
        {
          id: item.fieldValue.id,
          operationTypeId: item.fieldValue.operationTypeId,
        },
      ];
    }

    dataExpiryStore.isExpiryDataUpdated = false;
    UIStore.setPageLoader(true);
    approvalDataStore
      .upsertApprovalData([
        ApprovalDataModel.serializeExpiryData(
          fieldName,
          ENTITY_NAMES.OperationalEssential,
          item.fieldValue,
          newValue,
          item.vendorLocation.vendorLocationId
        ),
      ])
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe({
        next: (response: ApprovalDataModel[]) => {
          const request = new DataExpirationUptoDate({
            vendorLocationId: item.vendorLocation.vendorLocationId,
            dataExpSectionTypeId: sectionTypeId,
          });
          dataExpiryStore
            ?.upsertUpToDateConfirm(request.serialize())
            .pipe(
              takeUntil(unsubscribe.destroy$),
              finalize(() => UIStore.setPageLoader(false))
            )
            .subscribe({
              next: (response: DataExpirationUptoDate) => {
                if (response.isExpiryDataUpdate) {
                  const excludedFields = [
                    'appliedOperationType',
                    'airToGroundFrequency',
                    'managerName',
                    'asstManagerName',
                  ];
                  if (!excludedFields.includes(fieldName)) {
                    BaseStore.showAlert('Data updated successfully', 0);
                  }
                }
                dataExpiryStore.isExpiryDataUpdated = true;
              },
              error: error => {
                BaseStore.showAlert(error.message, 0);
              },
            });
          setModalOpen(false);
        },
        error: error => {
          errorHandler(error.response.data.errors, '0');
        },
      });
  };

  return (
    <Box className={classes.innerBox2}>
      {dataExpiryStore?.dataExpiration &&
        [
          ...new Map(
            dataExpiryStore.dataExpiration.map(item => {
              let uniqueKey;
              if (getCardData(item.sectionType.name)?.name) {
                uniqueKey = `${getCardData(item.sectionType.name).name}-${item.vendorLocation.vendorLocationId}`;
              }
              return [ uniqueKey, item ];
            })
          ).values(),
        ].map(item => {
          const cardData = getCardData(item.sectionType.name);
          if (cardData?.name) {
            return (
              <Paper
                className={classes.paper}
                key={`${item.vendorLocation.vendorLocationId}-${item.sectionType.name}`}
                style={{
                  border:
                    daysLeftUntilExpiry(item.lastExpiryDate) === 'red'
                      ? '1px solid #DB063B'
                      : daysLeftUntilExpiry(item.lastExpiryDate) === 'yellow'
                        ? '1px solid #F2C12C'
                        : 'none',
                }}
              >
                <Box className={classes.contentBox}>
                  <Box className={classes.textBox}>
                    Update your <b>{cardData.name}</b> for {item.vendorLocation.name} -{' '}
                    {item?.vendorLocation?.airportReference?.displayCode ||
                      item.vendorLocation?.vendorLocationCityReferenceModel?.cityReference?.cappsCode}
                    .
                  </Box>
                  <Box className={classes.primaryButton}>
                    {cardData.showEditButton ? (
                      <PrimaryButton
                        variant="contained"
                        color="primary"
                        startIcon={<EditIcon />}
                        onClick={() => {
                          dataExpiryStore.setInputValue('');
                          setModalProps({
                            item,
                            cardData,
                            handleSave,
                            dataExpSectionTypeId: item.sectionType.id,
                            vendorLocationId: item.vendorLocation.vendorLocationId,
                          });
                          setModalOpen(true);
                        }}
                      >
                        Edit
                      </PrimaryButton>
                    ) : (
                      <PrimaryButton
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          const parseState = JSON.parse(JSON.stringify(item));
                          loadVendorLocationData(parseState);
                        }}
                      >
                        {`Go to ${cardData.name} Page`}
                      </PrimaryButton>
                    )}
                  </Box>
                </Box>
              </Paper>
            );
          }
        })}
      {isModalOpen && modalProps && <CardModal {...modalProps} onClose={() => setModalOpen(false)} />}
    </Box>
  );
};
export default inject('dataExpiryStore', 'vendorLocationStore', 'approvalDataStore')(observer(DataExpiryCard));
