import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  editorWrapperContainer: {
    overflow: 'auto',
  },
  toggle: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    marginTop: '10px',
    '& p': {
      fontSize: '14px',
      fontWeight: 600,
    },
  },
  heading: {
    fontWeight: 600,
    fontSize: '14px',
  },
  innerBox2: {
    flexGrow: 1,
    paddingBottom: 10,
  },
  paper: {
    padding: theme.spacing(3),
    minHeight: '200px',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    position: 'relative',
  },
  slideCount: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
  },
  innerBox3: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(6),
    gap: '10px',
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '10px',
  },
  headerActions: {
    justifyContent: 'space-between',
    borderBottom: '1px solid #D3D3D3',
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(1.5),
    '& button': {
      width: '150px',
      marginRight: theme.spacing(1),
    },
    '& h5': {
      fontSize: '1.625rem',
    },
  },
  defaultButton: {
    '& button': {
      // border: '1px solid #1976D2',
      padding: '4px 10px',
      height: '40px',
      borderRadius: '4px',
      fontSize: '14px',
      fontWeight: '400',
      minWidth: '100px',
      textTransform: 'capitalize',
      color: '#1976D2',
    },
  },
  primaryButton: {
    '& button': {
      background: '#1976D2',
      color: '#fff',
      minWidth: '100px',
      height: '40px',
    },
    '& button:disabled': {
      background: '#afafaf',
      border: 'none',
      color: 'white',
    },
  },
  buttonStyle: {
    height: '25px',
    cursor: 'pointer',
    color: '#1976D2',
  },
}));
