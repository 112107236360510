import { CoreModel, ISelectOption, modelProtection } from '@wings-shared/core';
import { SettingBaseModel } from './SettingBase.model';
import { CityModel, CountryModel, StateModel } from '@uplink/shared';
import { RootDataStore } from '@uplink-shared/layout';

@modelProtection
export class DropOffAddressModel extends CoreModel implements ISelectOption {
  id: number = 0;
  pickUpLocationCrew: SettingBaseModel = new SettingBaseModel();  
  pickUpLocationPax: SettingBaseModel = new SettingBaseModel();
  vendorLocationPassengerTransportId: number = 0;
  addressType: SettingBaseModel = new SettingBaseModel();
  addressLine1: string = '';
  addressLine2: string = '';
  countryReference: CountryModel = new CountryModel();
  stateReference: StateModel = new StateModel();
  cityReference: CityModel = new CityModel();
  zipCode: string = '';

  constructor(data?: Partial<DropOffAddressModel>) {
    super(data);
    Object.assign(this, data);
  }

  static deserialize(apiData: DropOffAddressModel): DropOffAddressModel {
    if (!apiData) {
      return new DropOffAddressModel();
    }
    const data: Partial<DropOffAddressModel> = {
      ...apiData,
      pickUpLocationCrew: SettingBaseModel.deserialize(apiData.pickUpLocationCrew),
      pickUpLocationPax: SettingBaseModel.deserialize(apiData.pickUpLocationPax),
      addressType: SettingBaseModel.deserialize(apiData.addressType),
      countryReference: CountryModel.deserialize(apiData.countryReference),
      stateReference: StateModel.deserialize(apiData.stateReference),
      cityReference: CityModel.deserialize(apiData.cityReference),
    };
    return new DropOffAddressModel(data);
  }

  static deserializeList(apiDataList: DropOffAddressModel[]): DropOffAddressModel[] {
    return apiDataList ? apiDataList.map((apiData: any) => DropOffAddressModel.deserialize(apiData)) : [];
  }

  public serialize(data: DropOffAddressModel) {
    return {
      id: data.id ? data.id : this.id || 0,
      userId: this.userId,
      vendorLocationId: RootDataStore.locationData.locationId,
      addressLine1: data?.addressLine1,
      addressLine2: null,
      countryId: data?.hqAddressCountry?.id,
      countryCode: data?.hqAddressCountry?.isO2Code,
      countryName: data?.hqAddressCountry?.commonName,
      stateId: data?.hqAddressState?.id,
      stateCode: data?.hqAddressState?.isoCode,
      stateName: data?.hqAddressState?.commonName,
      cityId: data?.hqAddressCity?.id,
      cityCode: data?.hqAddressCity?.cappsCode,
      cityName: data?.hqAddressCity?.commonName,
      zipCode: data.zipCode,
      pickUpLocationCrewId: data.pickUpLocationCrew?.id,
      pickUpLocationPaxId: data.pickUpLocationPax?.id
    };
  }

  public get label(): string {
    return this.name;
  }

  public get value(): string | number {
    return this.id;
  }
}
