import { CoreModel, ISelectOption, modelProtection } from '@wings-shared/core';
import { VendorLocationModel } from './VendorLocation.model';
import { SettingBaseModel } from './SettingBase.model';
import { RootDataStore } from '@uplink-shared/layout';
import { Airports } from './Airports.model';
import { VendorLocationAddressModel } from './VendorLocationAddress.model';

@modelProtection
export class HandlerEventsModel extends CoreModel implements ISelectOption {
  id: number = 0;
  refDataId: number = 0;
  name: string = '';
  desciption: string = '';
  eventTypeId: number = 0;
  eventTypeName: string = '';
  eventCategory: VendorLocationModel = new VendorLocationModel();
  eventCategoryId: number = 0;
  eventCategoryName: string = '';
  url: string = '';
  startDate: Date = new Date();
  endDate: Date = new Date();
  
  constructor(data?: Partial<HandlerEventsModel>) {
    super(data);
    Object.assign(this, data);
  }

  public serialize(statusId: number): HandlerEventsModel {
    return {
      userId: this.userId || '',
      id: this.id || 0,
      refDataId: this.refDataId || 0,
      eventTypeId: this.eventTypeId?.id,
      eventTypeName: this.eventTypeName?.name,
      startDate: this.startDate,
      endDate: this.endDate,
    };
  }

  static deserialize(apiData: HandlerEventsModel): HandlerEventsModel {
    if (!apiData) {
      return new HandlerEventsModel();
    }
    const data: Partial<HandlerEventsModel> = {
      ...apiData,
      id: apiData?.id,
      refDataId:apiData?.refDataId,
      name: apiData.name,
      description: apiData.description,
      eventTypeName: apiData.eventTypeName,
      eventCategoryName: apiData.eventCategoryName,
      url: apiData.url,
      startDate: apiData.startDate,
      endDate: apiData.endDate,
    };
    return new HandlerEventsModel(data);
  }

  static deserializeList(apiDataList: HandlerEventsModel[]): HandlerEventsModel[] {
    return apiDataList ? apiDataList.map(apiData => HandlerEventsModel.deserialize(apiData)) : [];
  }

  public get label(): string {
    return this.eventTypeName.name;
  }

  public get value(): string | number {
    return this.id;
  }
}
