import React, { FC, useEffect } from 'react';
import { Dialog } from '@uvgo-shared/dialog';
import { PrimaryButton } from '@uvgo-shared/buttons';
import { Box, InputAdornment, TextField, withStyles } from '@material-ui/core';
import { styles } from './CardModal.styles';
import { IClasses } from '@wings-shared/core';
import { EDITOR_TYPES } from '@uplink-shared/form-controls';
import classNames from 'classnames';
import { DataExpiryStore } from '../../../../Stores';
import { inject } from 'mobx-react';

interface Props {
  classes?: IClasses;
  item: any;
  cardData: any;
  handleSave: (value: any, dataExpSectionTypeId: number, item: any, fieldName?: string) => void;
  onClose: () => void;
  dataExpSectionTypeId: number;
  dataExpiryStore: DataExpiryStore;
}

export const validateInput = (value, rules) => {
  if (!value) {
    return '';
  }

  let error = '';
  const messages = {
    required: 'This field is required.',
    numeric: 'This field must be a number.',
    'between:2,100': 'This field must be between 2 and 100 characters.',
    'between:0,999.99999': 'This field must be between 0 and 999.99999.',
    regex: 'This format is invalid.',
  };

  const checkBetween = (value, rule) => {
    const [ , range ] = rule.split('between:');
    const [ min, max ] = range.split(',').map(Number);
    return value.length >= min && value.length <= max;
  };
  for (const rule of rules.split('|')) {
    if (rule === 'required' && !value.trim()) {
      error = messages[rule];
      break;
    } else if (rule.startsWith('between:') && !checkBetween(value, rule.replace('string|', ''))) {
      error = messages[rule.replace('string|', '')];
      break;
    } else if (rule.startsWith('numeric|between:') && !checkBetween(Number(value), rule.replace('numeric|', ''))) {
      error = messages[rule.replace('numeric|', '')];
      break;
    } else if (rule === 'numeric' && isNaN(value)) {
      error = messages[rule];
      break;
    } else if (rule.startsWith('regex:')) {
      let regexString = rule.replace('regex:', '').replace(/^\/|\/$/g, '');
      regexString = regexString.replace(/\\\\/g, '\\');
      const regex = new RegExp(regexString);
      const beforeDecimal = value.split('.')[0];
      if (beforeDecimal.length > 3) {
        error = messages['between:0,999.99999'];
        break;
      }
      if (!regex.test(String(value))) {
        error = messages.regex;
        break;
      }
    }
  }

  return error;
};

const CardModal: FC<Props> = ({
  classes,
  item,
  handleSave,
  onClose,
  dataExpSectionTypeId,
  cardData,
  dataExpiryStore,
}) => {
  const [ errors, setErrors ] = React.useState({});

  const handleInputChange = event => {
    const value = event.target.value;
    const error = validateInput(value, cardData.rules || '');
    setErrors({ ...errors, [cardData.type]: error });
    dataExpiryStore.setInputValue(value);
  };

  useEffect(() => {
    if (item.sectionType.id === 9) {
      dataExpiryStore.setInputValue(item.fieldValue.name);
    } else dataExpiryStore.setInputValue(item.fieldValue);
  }, [ item.fieldValue ]);

  const getEditableContent = item => {
    const customInputRoot = classNames({
      [classes.textInput]: true,
    });
    
    switch (cardData.type) {
      case EDITOR_TYPES.TEXT_FIELD:
        return (
          <TextField
            autoComplete="off"
            label={cardData.name ? cardData.name : ''}
            type={'text'}
            variant="outlined"
            value={dataExpiryStore.inputValue}
            InputLabelProps={{
              classes: {
                root: classes.labelRoot,
              },
            }}
            onChange={handleInputChange}
            InputProps={{
              endAdornment: <InputAdornment position="start">{cardData.endAdormentValue || ''}</InputAdornment>,
            }}
            classes={{ root: customInputRoot }}
            error={!!errors[cardData.type]}
            helperText={errors[cardData.type]}
            onKeyDown={e => {
              if (!cardData.isEditable) {
                e.preventDefault();
              }
            }}
          />
        );
    }
  };

  return (
    <Dialog
      closeBtn={false}
      isPreventDrag
      disableBackdropClick
      title={
        <Box className={classes.headerLabel}>
          Update your <b>{cardData.name}</b> for {item?.vendorLocation?.name} -{' '}
          {item?.vendorLocation?.airportReference?.displayCode ||
            item?.vendorLocation?.vendorLocationCityReferenceModel?.cityReference?.cappsCode}
          .
        </Box>
      }
      open={true}
      onClose={onClose}
      dialogContent={() => getEditableContent(item)}
      dialogActions={() => (
        <Box sx={{ display: 'flex' }}>
          <div className={`${classes.defaultButton}`}>
            <PrimaryButton color="primary" variant="text" onClick={onClose} size="large">
              Cancel
            </PrimaryButton>
          </div>
          <div className={`${classes.primaryButton}`}>
            <PrimaryButton
              color="primary"
              variant="contained"
              onClick={() => handleSave(dataExpiryStore.inputValue, dataExpSectionTypeId, item, cardData?.fieldName)}
              size="large"
              disabled={!dataExpiryStore.inputValue || errors[cardData.type]}
            >
              Confirm
            </PrimaryButton>
          </div>
        </Box>
      )}
    />
  );
};

export default inject('dataExpiryStore')(withStyles(styles)(CardModal));
