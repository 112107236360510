import React, { useEffect } from 'react';
import { Box, Typography } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import { BaseStore, DataExpiryStore } from '../../../../Stores';
import { RootDataStore, SidebarStore } from '@uplink-shared/layout';
import LocationContacts from '../../../VendorLocations/Components/LocationContacts/LocationContacts';
import { useLocation, useNavigate } from 'react-router';
import { useStyles } from './ExpiryContact.styles';
import { PrimaryButton } from '@uvgo-shared/buttons';
import { HelpRounded } from '@material-ui/icons';
import { DataExpirationUptoDate } from '../../../Shared/Models/DataExpirationUptoDate.model';
import { finalize, takeUntil } from 'rxjs/operators';
import { useUnsubscribe } from '@wings-shared/hooks';
import { UIStore } from '@wings-shared/core';

interface Props {
  dataExpiryStore: DataExpiryStore;
}

const ExpiryContact: React.FC<Props> = ({ dataExpiryStore }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dataExpiryState = location.state.state;
  const classes = useStyles();
  const unsubscribe = useUnsubscribe();

  useEffect(() => {
    if (location.state.state) {
      dataExpiryStore.dataExpiryState = location.state.state;
    }
    SidebarStore.setNavLinks([], 'vendor', 'Contacts', '/vendor');
  }, [ location.state.state, dataExpiryStore?.dataExpiryState ]);

  const handleConfirm = () => {
    UIStore.setPageLoader(true);
    const request = new DataExpirationUptoDate({
      vendorLocationId: RootDataStore.locationData.locationId,
      dataExpSectionTypeId: dataExpiryStore.dataExpiryState.sectionType.id,
    });
    dataExpiryStore
      ?.upsertUpToDateConfirm(request.serialize())
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe({
        next: (response: DataExpirationUptoDate) => {
          if (response.isExpiryDataUpdate) {
            BaseStore.showAlert('Data updated successfully', 0);
            navigate('/vendor', {
              state: {
                activeStep: location.state?.activeStep,
              },
            });
          } else {
            BaseStore.showAlert(response.message.toString(), 0);
          }
        },
        error: error => {
          BaseStore.showAlert(error.message, 0);
        },
      });
  };

  return (
    <Box>
      <Box className={classes.headerConfirmation}>
        <Box className={classes.innerHeader}>
          <HelpRounded style={{ color: '#D16124' }} />
          <Typography>
            Are your <strong>{dataExpiryState?.sectionTypeName}</strong> for
            {dataExpiryState?.vendorLocation.name} -{' '}
            {dataExpiryState?.vendorLocation?.airportReference?.displayCode ||
              dataExpiryStore.dataExpiryState?.vendorLocation?.vendorLocationCityReferenceModel?.cityReference
                ?.cappsCode}{' '}
            up to date?
          </Typography>
        </Box>
        <Box>
          <PrimaryButton
            variant="text"
            onClick={() =>
              navigate('/vendor', {
                state: {
                  activeStep: location.state?.activeStep,
                }
              })
            }
          >
            Back
          </PrimaryButton>
          <PrimaryButton variant="contained" onClick={handleConfirm}>
            Confirm
          </PrimaryButton>
        </Box>
      </Box>
      <LocationContacts />
    </Box>
  );
};

export default inject('dataExpiryStore')(observer(ExpiryContact));
