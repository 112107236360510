import { CoreModel, ISelectOption, modelProtection } from '@wings-shared/core';
import { DropOffAddressModel } from './DropOffAddress.model';
import { RootDataStore } from '@uplink-shared/layout';

@modelProtection
export class PassengerTransportModel extends CoreModel implements ISelectOption {
  id: number = 0;
  vendorLocationId: number = 0;
  passengerTransportType: number = 0;
  isSameAsArrival: boolean = false;
  isArrangeAirSideDropoff: boolean = false;
  isSameAsDeparture: boolean = false;
  landsideDropOffAddress: DropOffAddressModel = new DropOffAddressModel();
  airsideDropOff: DropOffAddressModel = new DropOffAddressModel();
  additionalInfo: string = '';

  constructor(data?: Partial<PassengerTransportModel>) {
    super(data);
    Object.assign(this, data);
  }

  static deserialize(apiData: PassengerTransportModel): PassengerTransportModel {
    if (!apiData) {
      return new PassengerTransportModel();
    }
    const data: Partial<PassengerTransportModel> = {
      ...apiData,
      landsideDropOffAddress: DropOffAddressModel.deserialize(apiData.landsideDropOffAddress),
      airsideDropOff: DropOffAddressModel.deserialize(apiData.airsideDropOff),
    };
    return new PassengerTransportModel(data);
  }

  static deserializeList(apiDataList: PassengerTransportModel[]): PassengerTransportModel[] {
    return apiDataList ? apiDataList.map((apiData: any) => PassengerTransportModel.deserialize(apiData)) : [];
  }

  static serialize(item) {
    return {
      id: item.id || 0,
      userId: item.userId || '',
      vendorLocationId: RootDataStore.locationData.locationId,
      passengerTransportType: item.passengerTransportType || 1,
      isSameAsArrival: item.isSameAsArrival,
      isArrangeAirSideDropoff: item.isArrangeAirSideDropoff,
      isSameAsDeparture: item.isSameAsDeparture,
      landsideDropOffAddress: item.landsideDropOffAddress,
      airsideDropOff: !item.isSameAsDeparture ? item.airsideDropOff : null,
      additionalInfo: item.additionalInfo,
    };
  }

  public serializelist(data) {
    return data.length > 0
      ? data.map((item: PassengerTransportModel) => {
        return PassengerTransportModel.serialize(item);
      })
      : [];
  }

  public get label(): string {
    return this.name;
  }

  public get value(): string | number {
    return this.id;
  }
}
