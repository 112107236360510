import React, { useEffect, useCallback } from 'react';
import { 
  Box, 
  List, 
  ListItem, 
  ListItemIcon, 
  ListItemText, 
  Typography
} from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import { BaseStore, DataExpiryStore } from '../../../../../Stores';
import { IClasses, UIStore } from '@wings-shared/core';
import { useStyles } from './VendorGeneralInfoCard.styles';
import { 
  HelpRounded, 
  Warning, 
  Cancel, 
  FiberManualRecord 
} from '@material-ui/icons';
import DataExpiryCard from '../DataExpiryCard/DataExpiryCard';
import { useLocation, useNavigate } from 'react-router';
import { PrimaryButton } from '@uvgo-shared/buttons';
import { RootDataStore, SidebarStore } from '@uplink-shared/layout';
import { AuthStore } from '@uplink-shared/security';
import { finalize, takeUntil } from 'rxjs/operators';
import { useUnsubscribe } from '@wings-shared/hooks';
import { SidebarMenus } from '../../../../Shared';
import { 
  DataExpirationUptoDate 
} from '../../../../Shared/Models/DataExpirationUptoDate.model';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import { Dialog } from '@uvgo-shared/dialog';
import { ModeStore } from '@wings-shared/mode-store';

interface Props {
  classes?: IClasses;
  data?: any[];
  dataExpiryStore: DataExpiryStore;
}

const VendorGeneralInfoCard: React.FC<Props> = ({ dataExpiryStore, data }) => {
  const classes = useStyles();
  const location = useLocation();
  const unsubscribe = useUnsubscribe();
  const hrefURL = window.location.href;
  const navigate = useNavigate();

  useEffect(() => {
    dataExpiryStore.documentNameList = []
    if (data && data.length > 0) {
      const documentNameList = data
        .filter(document => {
          if(document.endDate){
            const isExpiring = daysLeftUntilExpiry(document.endDate);
            return isExpiring;
          }
        })
        .map(document => {
          return document.documentName.name;
        });
      dataExpiryStore.documentNameList = documentNameList;
    }
  }, [ data ]);

  const sectionTypeMapping = useCallback(
    () => ({
      addresses: 'VendorLocationAddress',
      contact: 'VendorLocationContact',
      documents: 'VendorLocationDocument',
      'operational-essential': 'VendorLocationServiceComm',
      pricing: 'ServiceItemPricing',
    }),
    []
  );

  const sectionTypeData = useCallback(
    () => ({
      VendorAddress: { name: 'Addresses', url: '/vendor/addresses' },
      VendorContact: { name: 'Contacts', url: '/vendor/contact' },
      VendorDocument: { name: 'Documents', url: '/vendor/documents' },
      VendorLocationAddress: { name: 'Addresses', url: '/vendor/location/addresses' },
      VendorLocationContact: { name: 'Contacts', url: '/vendor/location/contact' },
      VendorLocationDocument: { name: 'Documents', url: '/vendor/location/documents' },
      VendorLocationServiceComm: { name: 'Service Comm', url: '/vendor/location/operational-essential' },
      ServiceItemPricing: { name: 'Pricing', url: '/vendor/location/pricing' },
    }),
    []
  );

  const extractCardData = useCallback(() => {
    const extractedSegment = hrefURL.split('/location/').pop();
    const mappedSectionType = sectionTypeMapping()[extractedSegment];

    if (mappedSectionType) {
      const filteredData = dataExpiryStore.dataExpiration.filter(data => {
        return (
          data.vendorLocation.vendorLocationId === RootDataStore.locationData.locationId &&
          data.sectionType.name === mappedSectionType
        );
      });
      dataExpiryStore.dataExpiryState = filteredData[0];
    }
  }, [ dataExpiryStore.dataExpiration, location.state, hrefURL, sectionTypeMapping ]);

  const getCardData = useCallback(sectionType => sectionTypeData()[sectionType] || { name: '', url: '' }, [
    sectionTypeData,
  ]);

  const dialogContent = () => {
    return (
      <List>
        {dataExpiryStore.documentNameList &&
          dataExpiryStore.documentNameList.map(item => (
            <ListItem>
              <ListItemIcon style={{ minWidth: 0, marginRight: '10px' }}>
                <FiberManualRecord fontSize="small" />
              </ListItemIcon>
              <ListItemText primary={item} />
            </ListItem>
          ))}
      </List>
    );
  };

  const daysLeftUntilExpiry = lastExpiryDate => {
    const currentDate = new Date();
    const expiryDate = new Date(lastExpiryDate);

    const differenceInMilliseconds = expiryDate - currentDate;

    const differenceInDays = Math.ceil(differenceInMilliseconds / (1000 * 60 * 60 * 24));
    if (differenceInDays <= 15) return true;
    else return false;
  };

  const popupModal = () => {
    ModalStore.open(
      <Dialog
        title="Please upload following document(s) to confirm all are up to date:"
        open={true}
        onClose={() => ModalStore.close()}
        dialogContent={dialogContent}
        dialogActions={() => null}
        isPreventDrag={true}
      />
    );
  };

  const handleConfirm = () => {
    dataExpiryStore.isExpiryDataUpdated = false;
    const extractedSegment = hrefURL.split('/location/').pop();
    UIStore.setPageLoader(true);
    const request = new DataExpirationUptoDate({
      vendorLocationId: RootDataStore.locationData.locationId,
      dataExpSectionTypeId: dataExpiryStore.dataExpiryState.sectionType.id,
    });
    dataExpiryStore
      ?.upsertUpToDateConfirm(request.serialize())
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe({
        next: (response: DataExpirationUptoDate) => {
          if (response.isExpiryDataUpdate) {
            BaseStore.showAlert('Data updated successfully', 0);
            dataExpiryStore.isExpiryDataUpdated = true
          } else {
            if (extractedSegment?.includes('documents') && dataExpiryStore.documentNameList.length > 0) {
              popupModal();
            } else {
              BaseStore.showAlert(response.message.toString(), 0);
            }
          }
        },
        error: error => {
          BaseStore.showAlert(error.message, 0);
        },
      });
  };

  const ConfirmationBanner = ({ type, location }) => (
    <Box
      className={classes.headerConfirmation}
      sx={{
        border:
          type === 'location'
            ? '1px solid #D16124'
            : sorteDataBasedOnDate() === 'red'
              ? '1px solid #DB063B'
              : sorteDataBasedOnDate() === 'yellow' && '1px solid #F2C12C',
        bgcolor:
          type === 'location'
            ? 'rgba(209, 97, 36, 0.15)'
            : sorteDataBasedOnDate() === 'red'
              ? 'rgba(219, 6, 59, 0.05)'
              : sorteDataBasedOnDate() === 'yellow' && 'rgba(242, 193, 44, 0.05)',
      }}
    >
      <Box className={classes.innerHeader}>
        {type === 'location' ? (
          <>
            <HelpRounded style={{ color: '#D16124' }} />
            <Typography>
              Are your <strong>{getCardData(dataExpiryStore.dataExpiryState?.sectionType?.name)?.name}</strong> for{' '}
              {location?.vendorLocation?.name} - {location?.vendorLocation?.airportReference?.displayCode} up to date?
            </Typography>
          </>
        ) : sorteDataBasedOnDate() === 'red' ? (
          <>
            <Cancel style={{ color: '#DB063B' }} />
            <Typography>
              Please update your data. Information below is <strong>Required</strong> in order to stay in good standing.
            </Typography>
          </>
        ) : (
          sorteDataBasedOnDate() === 'yellow' && (
            <>
              <Warning style={{ color: '#F2C12C' }} />
              <Typography>Please update the data to keep your account in good standing.</Typography>
            </>
          )
        )}
      </Box>
      {type === 'location' && (
        <Box>
          <PrimaryButton variant="contained" onClick={handleConfirm}>
            Confirm
          </PrimaryButton>
        </Box>
      )}
    </Box>
  );

  const renderBanner = useCallback(() => {
    const isLocationPage = window.location.href.includes('/location/');
    if (
      getCardData(dataExpiryStore.dataExpiryState?.sectionType?.name)?.name &&
      dataExpiryStore.dataExpiration.length !== 0
    ) {
      return (
        <ConfirmationBanner
          type={isLocationPage ? 'location' : 'non-location'}
          location={dataExpiryStore.dataExpiryState}
        />
      );
    }
  }, [ dataExpiryStore.dataExpiryState ]);

  const loadExpirationsectionTypes = useCallback(() => {
    UIStore.setPageLoader(true);
    dataExpiryStore
      ?.getDataExpirationSectionType()
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe();
  }, [ dataExpiryStore ]);

  const loadExpirationDataByVendorId = useCallback(() => {
    UIStore.setPageLoader(true);
    dataExpiryStore
      ?.getDataExpirationByVendorId(AuthStore.vendorProfile.id)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe(() => {
        const extractedSectionType = hrefURL.split('/location/').pop();
        if (extractedSectionType) {
          extractCardData();
        }
        sorteDataBasedOnDate();
      });
  }, [ dataExpiryStore, location.state, dataExpiryStore.isExpiryDataUpdated ]);

  const loadExpirationDataByVendorLocationId = useCallback(() => {
    UIStore.setPageLoader(true);
    dataExpiryStore
      ?.getDataExpirationByVendorLocationId(RootDataStore.locationData.locationId)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe({
        next: response => {
          const extractedSectionType = hrefURL.split('/location/').pop();
          if (extractedSectionType) {
            extractCardData();
          }
          sorteDataBasedOnDate();
        },
        error: error => {
          dataExpiryStore.dataExpiration = [];
        },
      });
  }, [ dataExpiryStore, location.state, dataExpiryStore.isExpiryDataUpdated ]);

  const sorteDataBasedOnDate = useCallback(() => {
    const currentDate = new Date();
    const sortedData = dataExpiryStore.dataExpiration?.sort((a, b) => {
      const dateA = new Date(a.lastExpiryDate);
      const dateB = new Date(b.lastExpiryDate);
      return Math.abs(currentDate - dateA) - Math.abs(currentDate - dateB);
    });

    if (sortedData?.length > 0) {
      const diffInDays = Math.abs(
        Math.floor((currentDate - new Date(sortedData[0].lastExpiryDate)) / (1000 * 60 * 60 * 24))
      );
      if (diffInDays < 7) return 'red';
      if (diffInDays >= 7 && diffInDays < 15) return 'yellow';
      return 'orange';
    }
  }, [ dataExpiryStore.dataExpiration ]);

  useEffect(() => {
    SidebarStore.setNavLinks(SidebarMenus(), '/vendor/general-info');
    loadExpirationsectionTypes();

    if (hrefURL.includes('/location/') || hrefURL.includes('/locations/general-information')) {
      if (RootDataStore.locationData.locationId) {
        loadExpirationDataByVendorLocationId();
      }
    } else {
      if (AuthStore.vendorProfile?.id) {
        loadExpirationDataByVendorId();
      }
    }
  }, [
    AuthStore.vendorProfile?.id,
    RootDataStore.locationData.locationId,
    hrefURL,
    loadExpirationDataByVendorId,
    loadExpirationDataByVendorLocationId,
    loadExpirationsectionTypes
  ]);

  return (
    ModeStore.isDevModeEnabled ? (
      <Box className={classes.cardHeader}>
        {renderBanner()}
        <DataExpiryCard data={data} />
      </Box>
    ) : null
  );
};

export default inject('dataExpiryStore')(observer(VendorGeneralInfoCard));
