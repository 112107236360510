import { CoreModel, ISelectOption, modelProtection } from '@wings-shared/core';
import { StatusBaseModel } from './StatusBase.model';
import { Airports } from './Airports.model';
import { IAPIVMSVendorLocationComparison } from '../Interfaces';
import { VendorManagmentModel } from './VendorManagment.model';
import { AuthStore } from '@uplink-shared/security';
import { VendorLocationCountryModel } from './VendorLocationCountry.model';

@modelProtection
export class VendorLocationModel extends CoreModel implements ISelectOption {
  id: number = 0;
  code: string = '';
  name: string = '';
  vendorId: number = 0;
  vendor: {
    code: string;
    id: number;
    name: string;
  };
  vendorLocationId?: number = 0;
  uwaCode: string = '';
  vendorLocationStatus: StatusBaseModel = new StatusBaseModel();
  vendorLocationStatusId: number = 0;
  airportReferenceId: number = 0;
  airportReference?: Airports = new Airports();
  locationLegalName: string = '';
  rankAtAirport: number;
  locationStatusDetails: string = '';
  countryDataManagement: boolean = false;
  permitDataManagement: boolean = false;
  airportDataManagement: boolean = false;
  vendorLocationCityReferenceModel?: VendorLocationCountryModel = null;

  constructor(data?: Partial<VendorLocationModel>) {
    super(data);
    Object.assign(this, data);
  }

  static deserialize(apiData: IAPIVMSVendorLocationComparison): VendorLocationModel {
    if (!apiData) {
      return new VendorLocationModel();
    }
    const data: Partial<VendorLocationModel> = {
      ...apiData,
      vendorLocationId: apiData?.vendorLocationId || apiData.id,
      name: apiData?.name,
      code: apiData?.code,
      vendor: VendorManagmentModel.deserialize(apiData.vendor),
      vendorLocationStatus: StatusBaseModel.deserialize(apiData.vendorLocationStatus),
      airportReference: apiData.airportReference
        ? Airports.deserializeAirportReference(apiData.airportReference)
        : null,
      locationLegalName:apiData.locationLegalName,
      rankAtAirport:apiData.airportRank,
      locationStatusDetails: apiData.locationStatusDetails,
      countryDataManagement: apiData.countryDataManagement,
      permitDataManagement: apiData.permitDataManagement,
      airportDataManagement: apiData.airportDataManagement,
      vendorLocationCityReferenceModel: VendorLocationCountryModel.deserialize(apiData?.vendorLocationCityReference),
    };
    return new VendorLocationModel(data);
  }

  static deserializeList(apiDataList: IAPIVMSVendorLocationComparison[]): VendorLocationModel[] {
    return apiDataList ? apiDataList.map((apiData: any) => VendorLocationModel.deserialize(apiData)) : [];
  }

  public serialize() {
    return {
      id: this.id || 0,
      code: this.code?.replace(/\s/g, '') || undefined,
      name: this.name?.replace(/^\s*\s*$/, ''),
      vendorId: AuthStore.vendorProfile?.id,
      vendorLocationStatusId: this.vendorLocationStatus.id || 1,
      airportReferenceId: this.airportReference.id,
      airportReference: this.airportReference,
      userId: this.userId,      
      locationLegalName: this.locationLegalName?.replace(/^\s*\s*$/, '') || null,
      airportRank: parseInt(`${this.rankAtAirport}`) || null,
      locationStatusDetails: this.locationStatusDetails || null,
      countryDataManagement: Boolean(this.countryDataManagement) || false,
      permitDataManagement: Boolean(this.permitDataManagement) || false,
      airportDataManagement: Boolean(this.airportDataManagement) || false,
      vendorLocationCityReferenceModel: null,
    };
  }

  // required in auto complete
  public get label(): string {
    if (this.airportReference?.getDisplayCode()) {
      return `${this.name} (${this.airportReference?.getDisplayCode()})`;
    }
    if (this.vendorLocationCityReferenceModel !== null) {
      if (
        this.vendorLocationCityReferenceModel?.cityReference?.code ||
        this.vendorLocationCityReferenceModel?.cityReference?.name
      ) {
        return `${this.name} (${this.vendorLocationCityReferenceModel?.cityReference?.code ||
          this.vendorLocationCityReferenceModel?.cityReference?.name})`;
      }
      return `${this.name}`;
    }
    return `${this.name}`;
  }

  public headerLabel(): string {
    if(this.airportReference?.getDisplayCode()){
      `${this.airportReference?.getDisplayCode()}`
    }
    if (this.vendorLocationCityReferenceModel !== null) {
      if (
        this.vendorLocationCityReferenceModel?.cityReference?.cappsCode ||
        this.vendorLocationCityReferenceModel?.cityReference?.name
      ) {
        return `${this.vendorLocationCityReferenceModel?.cityReference?.cappsCode ||
          this.vendorLocationCityReferenceModel?.cityReference?.name}`;
      }
    }
    return `${this.airportReference?.getDisplayCode()}`
  }

  public get value(): string | number {
    return this.id;
  }
}
