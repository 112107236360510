import { observable, action } from 'mobx';
import {
  COLLECTION_NAMES,
  HandlerBulletinsModel,
} from '../Modules/Shared';
import { IAPIGridRequest, IAPIPageResponse, Utilities } from '@wings-shared/core';
import { Observable } from 'rxjs';
import { baseApiPath, HttpClient } from '@uplink/shared';
import { vendorManagementHeaders } from './Base.store';
import { apiUrls } from './API.url';
import { Logger } from '@uplink-shared/security';
import { map, tap } from 'rxjs/operators';
import { AlertStore } from '@uvgo-shared/alert';
import { HandlerEventsModel } from '../Modules/Shared/Models/HandlerEvents.model';

export class EventsStore {
  @observable public handlerEventsList: HandlerEventsModel[] = [];

  @action
  public getWorldEvents(pageRequest?: IAPIGridRequest): Observable<IAPIPageResponse<HandlerEventsModel>> {
    const http: HttpClient = new HttpClient({
      baseURL: baseApiPath.vendorManagementNoSqlUrl,
      headers: vendorManagementHeaders,
    });
    const params: string = Utilities.buildParamString({
      CollectionName: 'WorldEvent',
      ...pageRequest,
    });

    return http.get<IAPIPageResponse<HandlerEventsModel>>(`/${apiUrls.vendorManagement}?${params}`).pipe(
      Logger.observableCatchError,

      map(response => {
        this.handlerEventsList = HandlerEventsModel.deserializeList(response.results);
        return { ...response, results: this.handlerEventsList };
      })
    );
  }

  @action
  public upsertVendorLocationHanlderEvents(payload: HandlerBulletinsModel): Observable<HandlerBulletinsModel> {
    const http = new HttpClient({ headers: vendorManagementHeaders });
    payload.id = payload.id === null ? 0 : payload.id;
    const isNewRequest: boolean = !Boolean(payload.id);
    const upsertRequest: Observable<HandlerBulletinsModel> = isNewRequest
      ? http.post<any>(`${baseApiPath.vendorManagementCoreUrl}/${apiUrls.WorldEventReference}`, payload)
      : http.put<any>(`${baseApiPath.vendorManagementCoreUrl}/${apiUrls.WorldEventReference}/${payload.id}`, payload);
    return upsertRequest.pipe(
      Logger.observableCatchError,
      tap(() => {
        if(payload.statusId === 1){
          AlertStore.info('Event saved successfully!')
        } else {
          AlertStore.info('Event deleted successfully!')
        }
      }),
      map(response => HandlerBulletinsModel.deserialize(response))
    );
  }
}
