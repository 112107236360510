import { auditFields } from '@uplink/shared';

export const transportFieldsWithRules = {
  ...auditFields,
  id: {
    label: 'Arrival/Departure Id',
  },
  pickUpLocationCrew: {
    label: 'Driver Pick up location Type - Crew',
    rules: 'required'
  },
  pickUpLocationPax: {
    label: 'Driver Pick up location Type - Pax',
    rules: 'required'
  },
  addressLine1: {
    label: 'What is the physical address for the drivers to find you?',
    rules: 'required|string|between:1,200',
  },
  hqAddressCountry: {
    label: 'Country*',
    rules: 'required'
  },
  hqAddressState: {
    label: 'State',
  },
  hqAddressCity: {
    label: 'City*',
    rules: 'required'
  },
  zipCode: {
    label: 'Zip code',
    rules: 'required|string|between:1,10',
  },
}
