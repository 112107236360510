import { createStyles, Theme } from '@material-ui/core';

export const styles = createStyles((theme: Theme) => ({
  dialogContainerWidth:{
    '& .MuiDialog-paperWidthSm':{
      width:'420px'
    }
  },
  editorWrapperContainer: {
    '& .MuiFormLabel-root': {
      fontSize: '12px',
    },
    '& .MuiOutlinedInput-adornedEnd': {
      cursor: 'pointer',
    },
    '& .MuiOutlinedInput-adornedEnd svg': {
      width: '16px !important',
      height: '16px !important',
    },
    '& .MuiChip-label': { 
      fontSize: '14px', 
      paddingTop: '2px' 
    },
    '& .MuiChip-colorPrimary': { 
      backgroundColor: '#004ba0' 
    },
  },
  headerActions: {
    justifyContent: 'space-between',
    borderBottom: '1px solid #D3D3D3',
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(3),
    '& button': {
      width: '150px',
      marginRight: '10px',
    },
    '& h5': {
      fontSize: '1.625rem',
    },
    '& .MuiTypography-h6': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      wordWrap: 'normal',
      whiteSpace: 'nowrap',
      fontSize: '1.625rem',
    },
  },
  defaultButton: {
    marginLeft: '16px',
    '& button': {
      padding: '4px 10px',
      height: '40px',
      borderRadius: '4px',
      fontSize: '14px',
      fontWeight: '400',
      width: '140px',
      minWidth: '140px',
      textTransform: 'capitalize',
      color: '#1976D2',
    },
  },
  primaryButton: {
    '& button': {
      background: '#1976D2',
      color: '#fff',
    },
    '& button:hover': {
      backgroundColor: '#63A4FF',
    },
    '& button:disabled': {
      background: '#afafaf',
      border: 'none',
    },
  },
  imageIcon: {
    height: '15px',
    width: '15px',
    cursor: 'pointer',
    color: '#1976D2',
    pointerEvents: 'all',
    marginLeft: theme.spacing(1),
  },
  mainTerminalOnlyWrapper:{
    '& .layout-contentWrapper [class*="MuiFormLabel-root"]':{
      fontSize: '14px'
    },
    '& > .MuiBox-root':{
      marginTop: '0',
      marginBottom: '15px'
    }
  },
  heading:{
    fontSize: '14px',
    fontWeight: 600,
    margin: '0 0 4px 0'
  },
}));
